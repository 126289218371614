import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import PrivateRoute from './PrivateRoute'; // Import PrivateRoute component

import LoginScreen from './auth/LoginScreen';
import SignupScreen from './auth/SignupScreen';
import LeadCreatePage from './Page/sales/LeadCreatePage';  
import LeadListPage from './Page/sales/LeadListPage';  
import SalesDashboard from './Page/sales/SalesDashboard'; 
import ManagerDashboard from './Page/manager/ManagerDashboard';  
import PreSalesTeamManagement from './Page/manager/PreSalesTeamManagement';  
import OperationsTeamManagement from './Page/manager/OperationsTeamManagement';  
import ProfileManagement from './Page/manager/ProfileManagement'; 
import TeamPerformanceManagement from './Page/manager/TeamPerformanceManagement';
import SalesMemberProfile from './Page/manager/SalesMemberProfile';
import OperationsMemberProfile from './Page/manager/OperationsMemberProfile';
import UserListPage from './Component/UserListPage';
import DashboardLayout from './Component/Sidebar';
import LeadPipelinePage from './Page/sales/LeadPipelinePage';
import PresalesTeamPerformancePage from './Page/sales/PresalesTeamPerformancePage';
import OperationsDashboardPage from './Page/oparetion/OperationsDashboardPage';
import OperationsLeadPipelinePage from './Page/oparetion/OperationsLeadPipelinePage';
import LeadActivityTracker from './Page/oparetion/LeadActivityTracker';
import TeamPerformanceMetrics from './Page/oparetion/TeamPerformanceMetrics';
import DashboardScreen from './Page/manager/dashboardscreen';
import UploadLeadData from './Page/manager/UploadLeadData';
import useAuth from './useAuth';
import PreTeamList from './Page/manager/PreTeamList';
import LeadList from './Page/manager/LeadList';
import AssignLeads from './Page/manager/AssginLeadToPresale';
import DemoBookingForm from './Page/sales/BookcustomerDemo';
import GetBookings from './Page/sales/GetBooking';
import OperationDashboard from './Page/oparetion/OperationDashboard';
import CreateCouponForm from './Page/sales/Couponcode';
import GetEmpCoupon from './Page/sales/GetEmpCoupon';
import CouponForm from './Page/sales/CouponForm';
import ApplyCoupon from './Page/sales/ApplyCoupon';
import GetDemoFromWebsite from './Page/oparetion/GetDemoFromWebsite';

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('token'));
  const [userRole, setUserRole] = useState(localStorage.getItem('role') || '');

  console.log("App Rendered: isLoggedIn =", isLoggedIn, ", userRole =", userRole);

  const handleLoginSuccess = (role) => {
    setIsLoggedIn(true);
    setUserRole(role);
  };
  useAuth(); // Automatically checks if the user is authenticated
  // useEffect(() => {
  //   localStorage.removeItem('token');
  //   localStorage.removeItem('user');
  //   localStorage.removeItem('role');   
  // }, []);


  return (
    <Routes>
      {/* Route for Login */}
      <Route
        path="/login"
        element={isLoggedIn ? <Navigate to="/" /> : <LoginScreen onLoginSuccess={handleLoginSuccess} />}
      />

      {/* Protected Routes */}
      <Route element={<PrivateRoute isLoggedIn={isLoggedIn} role={userRole} allowedRoles={['manager']} />}>
        <Route path="/manager-dashboard" element={<DashboardLayout><DashboardScreen /></DashboardLayout>} />
        <Route path="/upload-lead" element={<DashboardLayout><UploadLeadData /></DashboardLayout>} />
        <Route path="/assign-lead" element={<DashboardLayout><AssignLeads /></DashboardLayout>} />
        <Route path="/leadlist" element={<DashboardLayout><LeadList /></DashboardLayout>} />
        <Route path="/sales-team" element={<DashboardLayout><PreSalesTeamManagement /></DashboardLayout>} />
        <Route path="/sales-team-list" element={<DashboardLayout><PreTeamList /></DashboardLayout>} />
        <Route path="/operations-team" element={<DashboardLayout><OperationsTeamManagement /></DashboardLayout>} />
        <Route path="/profile" element={<DashboardLayout><ProfileManagement /></DashboardLayout>} />
        <Route path="/team-performance" element={<DashboardLayout><TeamPerformanceManagement /></DashboardLayout>} />
        <Route path="/sales-member/:id" element={<DashboardLayout><SalesMemberProfile  /></DashboardLayout>} />
        <Route path="/operations-member/:id" element={<DashboardLayout><OperationsMemberProfile /></DashboardLayout>} />
      </Route>

      {/* Sales Routes */}
      
      <Route element={<PrivateRoute isLoggedIn={isLoggedIn} role={userRole} allowedRoles={["sales"]} />}>
        <Route path="/sales-dashboard" element={<DashboardLayout><SalesDashboard /></DashboardLayout>} />
        <Route path="/book-demo" element={<DashboardLayout><DemoBookingForm /></DashboardLayout>} />
        <Route path="/getBookings" element={<DashboardLayout><GetBookings /></DashboardLayout>} />

        <Route path="/create-lead" element={<DashboardLayout><LeadCreatePage /></DashboardLayout>} />
        <Route path="/lead-list" element={<DashboardLayout><LeadListPage /></DashboardLayout>} />
        <Route path="/lead-pipeline" element={<DashboardLayout><LeadPipelinePage /></DashboardLayout>} />
        <Route path="/lead-team" element={<DashboardLayout><PresalesTeamPerformancePage /></DashboardLayout>} />
        <Route path="/getdemowwebsite" element={<DashboardLayout><GetDemoFromWebsite /></DashboardLayout>} />

        <Route path="/coupon" element={<DashboardLayout><CreateCouponForm /></DashboardLayout>} /> 
                  <Route path="/checkcoupon" element={<DashboardLayout><GetEmpCoupon /></DashboardLayout>} /> 
                  <Route path="/newcoupon" element={<DashboardLayout><CouponForm /></DashboardLayout>} /> 
                  <Route path="/ApplyCoupon" element={<DashboardLayout><ApplyCoupon /></DashboardLayout>} /> 
      </Route>

      {/* Operations Routes */}
      <Route element={<PrivateRoute isLoggedIn={isLoggedIn} role={userRole} allowedRoles={["operation"]} />}>
        <Route path="/operation-dashboard" element={<DashboardLayout><OperationsDashboardPage /></DashboardLayout>} />
        <Route path="/lead-opretion" element={<DashboardLayout><OperationsLeadPipelinePage /></DashboardLayout>} />
        <Route path="/lead-activity" element={<DashboardLayout><LeadActivityTracker /></DashboardLayout>} />
        <Route path="/lead-perform" element={<DashboardLayout><TeamPerformanceMetrics /></DashboardLayout>} />
        <Route path="/getBookingss" element={<DashboardLayout><OperationDashboard /></DashboardLayout>} />
        <Route path="/getdemoope" element={<DashboardLayout><GetDemoFromWebsite /></DashboardLayout>} />

      </Route>

      {/* Default Route (Redirect to Dashboard or Login based on login status) */}
      <Route path="/" element={isLoggedIn ? <Navigate to={`/${userRole}-dashboard`} /> : <Navigate to="/login" />} />
    </Routes>
  );
};

export default App;
