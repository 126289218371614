import React, { useState, useEffect } from 'react';
import {
  Box, Container, CircularProgress, Typography, Table, TableBody, TableCell, TableContainer, 
  TableHead, TableRow, Paper, IconButton, Tooltip, Button, Dialog, DialogTitle, DialogContent, 
  DialogActions, TextField, Select, MenuItem, InputLabel, FormControl, Snackbar, Alert, Chip, 
  Grid, Card, Avatar, Pagination
} from '@mui/material';
import {
  Refresh as RefreshIcon, School as SchoolIcon, EventAvailable as RescheduleIcon,
  CheckCircle as CheckCircleIcon, FilterAlt as FilterIcon,
  Bookmark as BookmarkIcon, BookmarkBorder as BookmarkBorderIcon
} from '@mui/icons-material';
// Add these imports with your other Material-UI imports


// Add these icon imports with your other icon imports

import {
    HelpOutline as HelpOutlineIcon,
    Schedule as ScheduleIcon,
    ThumbUp as ThumbUpIcon,
    ThumbDown as ThumbDownIcon,
  } from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment-timezone';
import axios from 'axios';
import baseUrl from '../../config';
import Link from '@mui/material/Link';
import Badge from '@mui/material/Badge';

// Icon imports
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import CancelIcon from '@mui/icons-material/Cancel';
const GetDemoFromWebsite = () => {
  const [bookings, setBookings] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [openAssignDialog, setOpenAssignDialog] = useState(false);
  const [openRescheduleDialog, setOpenRescheduleDialog] = useState(false);
  const [filters, setFilters] = useState({
    status: '',
    date: null,
    grade: '',
    sortBy: 'createdAt',
    sortOrder: 'desc',
    page: 1,
    limit: 10
  });
  const [assignData, setAssignData] = useState({ 
    teacherId: '', 
    demoDate: '', 
    demoTime: '', 
    timezone: 'Asia/Kolkata' 
  });
  const [rescheduleData, setRescheduleData] = useState({ 
    newDate: '', 
    newTime: '' 
  });
  const [timezones] = useState(moment.tz.names());
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    fetchBookingsAndTeachers();
  }, [filters]);

  const fetchBookingsAndTeachers = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const params = {
        ...filters,
        date: filters.date ? filters.date.format('YYYY-MM-DD') : null
      };

      const [bookingsResponse, teachersResponse] = await Promise.all([
        axios.get(`${baseUrl}api/newdemo/my-bookings`, {
          headers: { 'Authorization': `Bearer ${token}` },
          params
        }),
        axios.get(`${baseUrl}api/newdemo/all-teacher`, {
          headers: { 'Authorization': `Bearer ${token}` }
        })
      ]);

      setBookings(bookingsResponse.data.bookings || []);
      setTeachers(teachersResponse.data);
      setTotalPages(bookingsResponse.data.totalPages || 1);
      setError(null);
    } catch (err) {
      setError(err.message);
      showSnackbar('Error fetching data', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleAssignTeacher = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        `${baseUrl}api/newdemo/assign-demo-class`,
        {
          teacherId: assignData.teacherId,
          bookingId: selectedBooking._id,
          demoDate: assignData.demoDate,
          demoTime: assignData.demoTime,
          teacherTimezone: assignData.timezone,
          studentTimezone: selectedBooking.timezone || 'UTC'
        },
        { headers: { 'Authorization': `Bearer ${token}` } }
      );

      setBookings(prev =>
        prev.map(booking =>
          booking._id === selectedBooking._id
            ? { ...booking, teacher: teachers.find(t => t._id === assignData.teacherId), status: 'accepted' }
            : booking
        )
      );
      setOpenAssignDialog(false);
      showSnackbar('Teacher assigned successfully!', 'success');
    } catch (error) {
      showSnackbar('Error assigning teacher: ' + (error.response?.data?.message || error.message), 'error');
    }
  };

  const handleReschedule = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.put(
        `${baseUrl}api/newdemo/rescheduleBooking/${selectedBooking._id}`,
        {
          date: rescheduleData.newDate,
          time: rescheduleData.newTime
        },
        { headers: { 'Authorization': `Bearer ${token}` } }
      );
      setOpenRescheduleDialog(false);
      fetchBookingsAndTeachers();
      showSnackbar('Demo rescheduled successfully!', 'success');
    } catch (error) {
      showSnackbar('Error rescheduling booking: ' + error.message, 'error');
    }
  };

  const handleUpdateInterest = async (bookingId, interestStatus) => {
    try {
      const token = localStorage.getItem('token');
      await axios.patch(
        `${baseUrl}api/newdemo/updateInterest/${bookingId}`,
        { interested: interestStatus },
        { headers: { 'Authorization': `Bearer ${token}` } }
      );
      fetchBookingsAndTeachers();
      showSnackbar(`Marked as ${interestStatus ? 'interested' : 'not interested'}`, 'success');
    } catch (error) {
      showSnackbar('Error updating interest status: ' + error.message, 'error');
    }
  };

  const showSnackbar = (message, severity) => {
    setSnackbar({ open: true, message, severity });
  };

  const handleCloseSnackbar = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  const handleFilterChange = (key, value) => {
    setFilters(prev => ({ ...prev, [key]: value, page: 1 }));
  };

  const handlePageChange = (event, value) => {
    setFilters(prev => ({ ...prev, page: value }));
  };

  if (loading && bookings.length === 0) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
        <CircularProgress size={60} />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ textAlign: 'center', p: 4 }}>
        <Typography variant="h6" color="error" sx={{ mb: 2 }}>Error Loading Data</Typography>
        <Typography sx={{ mb: 3 }}>{error}</Typography>
        <Button variant="contained" onClick={fetchBookingsAndTeachers} startIcon={<RefreshIcon />}>
          Retry
        </Button>
      </Box>
    );
  }

  const FilterComponent = () => (
    <Card sx={{ mb: 3, p: 2 }}>
      <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <FilterIcon /> Filter Bookings
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth>
            <InputLabel>Status</InputLabel>
            <Select
              value={filters.status}
              onChange={(e) => handleFilterChange('status', e.target.value)}
              label="Status"
            >
              <MenuItem value="">All</MenuItem>
              {['pending', 'accepted', 'completed', 'notConducted'].map(status => (
                <MenuItem key={status} value={status}>{status}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label="Booking Date"
              value={filters.date}
              onChange={(newValue) => handleFilterChange('date', newValue)}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth>
            <InputLabel>Grade</InputLabel>
            <Select
              value={filters.grade}
              onChange={(e) => handleFilterChange('grade', e.target.value)}
              label="Grade"
            >
              <MenuItem value="">All</MenuItem>
              {[...Array(12).keys()].map(g => (
                <MenuItem key={g} value={g}>{g}th Grade</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth>
            <InputLabel>Sort By</InputLabel>
            <Select
              value={filters.sortBy}
              onChange={(e) => handleFilterChange('sortBy', e.target.value)}
              label="Sort By"
            >
              <MenuItem value="createdAt">Created At</MenuItem>
              <MenuItem value="timezone">Timezone</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth>
            <InputLabel>Sort Order</InputLabel>
            <Select
              value={filters.sortOrder}
              onChange={(e) => handleFilterChange('sortOrder', e.target.value)}
              label="Sort Order"
            >
              <MenuItem value="desc">Descending</MenuItem>
              <MenuItem value="asc">Ascending</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
          <Button variant="outlined" onClick={() => setFilters({
            status: '',
            date: null,
            grade: '',
            sortBy: 'createdAt',
            sortOrder: 'desc',
            page: 1,
            limit: 10
          })}>
            Reset Filters
          </Button>
          <Button variant="contained" onClick={fetchBookingsAndTeachers} startIcon={<RefreshIcon />}>
            Apply Filters
          </Button>
        </Grid>
      </Grid>
    </Card>
  );

  return (
    <Container maxWidth="xl" sx={{ py: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h5" fontWeight="bold">Demo Bookings Management</Typography>
        <Tooltip title="Refresh">
          <IconButton onClick={fetchBookingsAndTeachers}>
            <RefreshIcon />
          </IconButton>
        </Tooltip>
      </Box>

      <FilterComponent />

      <TableContainer component={Paper} sx={{ 
  borderRadius: 2, 
  boxShadow: 3,
  maxHeight: 'calc(100vh - 200px)',
  overflow: 'auto'
}}>
  <Table stickyHeader sx={{ minWidth: 1400 }} size="small">
    <TableHead>
      <TableRow>
        {[
          { label: 'Parent', key: 'parentName', width: '8%' },
          { label: 'Child', key: 'childName', width: '8%' },
          { label: 'Grade', key: 'grade', width: '5%' },
          { label: 'Contact Info', key: 'contact', width: '15%' },
        //   { label: 'Demo Date/Time', key: 'demoDate', width: '12%' },
          { label: 'Created', key: 'createdAt', width: '8%' },
          { label: 'Demo Link', key: 'demoLink', width: '10%' },
          { label: 'Teacher', key: 'teacher', width: '8%' },
          { label: 'Sales Rep', key: 'salesUser', width: '8%' },
          { label: 'Status', key: 'status', width: '8%' },
          { label: 'Interest', key: 'interested', width: '6%' },
          { label: 'Reschedules', key: 'rescheduleCount', width: '6%' },
          { label: 'Actions', key: 'actions', width: '10%', align: 'center' }
        ].map((column) => (
          <TableCell 
            key={column.key}
            sx={{ 
              backgroundColor: 'primary.main',
              color: 'white', 
              fontWeight: 'bold', 
              width: column.width, 
              textAlign: column.align || 'left',
              position: 'sticky',
              top: 0,
              zIndex: 1
            }}
          >
            {column.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
    <TableBody>
      {bookings.map((booking) => (
        <TableRow 
          key={booking._id} 
          hover 
          sx={{ 
            '&:last-child td': { borderBottom: 0 },
            '&:nth-of-type(even)': { backgroundColor: 'action.hover' }
          }}
        >
          {/* Parent */}
          <TableCell sx={{ fontWeight: 'medium' }}>
            {booking.parentName}
          </TableCell>
          
          {/* Child */}
          <TableCell>
            {booking.childName}
          </TableCell>
          
          {/* Grade */}
          <TableCell>
            {booking.grade || <Typography color="text.disabled">-</Typography>}
          </TableCell>
          
          {/* Contact Info */}
          <TableCell>
            <Box>
              <Typography sx={{ fontWeight: 'medium' }}>
                {booking.customerEmail || booking.email || <Typography color="text.disabled">-</Typography>}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {(booking.countryCode || '') + (booking.customerPhone || booking.phoneNumber || '-')}
              </Typography>
            </Box>
          </TableCell>
          
          {/* Demo Date/Time */}
          {/* <TableCell>
            <Box>
              <Typography sx={{ fontWeight: 'medium' }}>
                {moment(booking.demoDateIndia).format('MMM D, YYYY')}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {booking.demoSlotIST || moment(booking.demoDateIndia).format('h:mm A')}
                {booking.timezone && (
                  <Tooltip title={booking.timezone}>
                    <span> ({booking.timezone.split('/').pop()})</span>
                  </Tooltip>
                )}
              </Typography>
            </Box>
          </TableCell>
           */}
          {/* Created */}
          <TableCell>
            {moment(booking.createdAt).format('MMM D')}
            <Typography variant="body2" color="text.secondary">
              {moment(booking.createdAt).format('h:mm A')}
            </Typography>
          </TableCell>
          
          {/* Demo Link */}
          <TableCell>
            {booking.demoLink ? (
              <Box>
                <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
                  {booking.demoLink.joinUrl ? (
                    <Link 
                      href={booking.demoLink.joinUrl} 
                      target="_blank" 
                      rel="noopener noreferrer"
                      sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}
                    >
                      <OpenInNewIcon fontSize="inherit" /> Join
                    </Link>
                  ) : <Typography color="text.disabled">-</Typography>}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {booking.demoLink.startTimeUTC ? 
                    moment(booking.demoLink.startTimeUTC).format('h:mm A') : '-'}
                </Typography>
              </Box>
            ) : <Typography color="text.disabled">-</Typography>}
          </TableCell>
          
          {/* Teacher */}
          <TableCell>
            {booking.teacher ? (
              <Chip 
                avatar={<Avatar sx={{ width: 24, height: 24 }}>{booking.teacher.name[0]}</Avatar>} 
                label={booking.teacher.name} 
                variant="outlined"
                size="small"
              />
            ) : (
              <Button 
                size="small" 
                variant="outlined"
                onClick={() => {
                  setSelectedBooking(booking);
                  setAssignData({ 
                    teacherId: '', 
                    demoDate: moment(booking.demoDateIndia).format('YYYY-MM-DD'),
                    demoTime: booking.demoSlotIST || moment(booking.demoDateIndia).format('HH:mm'),
                    timezone: booking.timezone || 'Asia/Kolkata'
                  });
                  setOpenAssignDialog(true);
                }}
              >
                Assign
              </Button>
            )}
          </TableCell>
          
          {/* Sales Rep */}
          <TableCell>
            {booking.salesUser ? (
              <Chip 
                avatar={<Avatar sx={{ width: 24, height: 24 }}>{booking.salesUser.name[0]}</Avatar>} 
                label={booking.salesUser.name} 
                variant="outlined"
                size="small"
              />
            ) : <Typography color="text.disabled">-</Typography>}
          </TableCell>
          
          {/* Status */}
          <TableCell>
            <Chip 
              label={booking.status} 
              size="small"
              sx={{ 
                textTransform: 'capitalize',
                backgroundColor: 
                  booking.status === 'completed' ? 'success.light' :
                  booking.status === 'pending' ? 'warning.light' :
                  booking.status === 'notConducted' ? 'error.light' : 'info.light',
                color: 
                  booking.status === 'completed' ? 'success.contrastText' :
                  booking.status === 'pending' ? 'warning.contrastText' :
                  booking.status === 'notConducted' ? 'error.contrastText' : 'info.contrastText'
              }}
            />
          </TableCell>
          
          {/* Interest */}
          <TableCell>
            {booking.interested === true ? (
              <CheckCircleIcon color="success" fontSize="small" />
            ) : booking.interested === false ? (
              <CancelIcon color="error" fontSize="small" />
            ) : (
              <HelpOutlineIcon color="disabled" fontSize="small" />
            )}
          </TableCell>
          
          {/* Reschedules */}
          <TableCell align="center">
            <Badge 
              badgeContent={booking.rescheduleCount || 0} 
              color="secondary"
              sx={{ '& .MuiBadge-badge': { right: -5 } }}
            />
          </TableCell>
          
          {/* Actions */}
          <TableCell>
            <Box sx={{ display: 'flex', gap: 1, justifyContent: 'center' }}>
              <Tooltip title="Assign Teacher">
                <IconButton
                  size="small"
                  color="primary"
                  onClick={() => {
                    setSelectedBooking(booking);
                    setAssignData({ 
                      teacherId: booking.teacher?._id || '', 
                      demoDate: moment(booking.demoDateIndia).format('YYYY-MM-DD'),
                      demoTime: booking.demoSlotIST || moment(booking.demoDateIndia).format('HH:mm'),
                      timezone: booking.timezone || 'Asia/Kolkata'
                    });
                    setOpenAssignDialog(true);
                  }}
                >
                  <SchoolIcon fontSize="small" />
                </IconButton>
              </Tooltip>
              
              <Tooltip title="Reschedule">
                <IconButton
                  size="small"
                  color="primary"
                  onClick={() => {
                    setSelectedBooking(booking);
                    setRescheduleData({
                      newDate: moment(booking.demoDateIndia).format('YYYY-MM-DD'),
                      newTime: booking.demoSlotIST || moment(booking.demoDateIndia).format('HH:mm')
                    });
                    setOpenRescheduleDialog(true);
                  }}
                >
                  <ScheduleIcon fontSize="small" />
                </IconButton>
              </Tooltip>
              
              <Tooltip title="Mark as Interested">
                <IconButton 
                  size="small" 
                  color="success"
                  onClick={() => handleUpdateInterest(booking._id, true)}
                >
                  <ThumbUpIcon fontSize="small" />
                </IconButton>
              </Tooltip>
              
              <Tooltip title="Mark as Not Interested">
                <IconButton 
                  size="small" 
                  color="error"
                  onClick={() => handleUpdateInterest(booking._id, false)}
                >
                  <ThumbDownIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
</TableContainer>

      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
        <Pagination
          count={totalPages}
          page={filters.page}
          onChange={handlePageChange}
          color="primary"
        />
      </Box>

      {/* Assign Teacher Dialog */}
      <Dialog open={openAssignDialog} onClose={() => setOpenAssignDialog(false)} maxWidth="sm" fullWidth>
        <DialogTitle sx={{ bgcolor: 'primary.main', color: 'white' }}>Assign Teacher</DialogTitle>
        <DialogContent sx={{ pt: 3 }}>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Teacher</InputLabel>
            <Select
              value={assignData.teacherId}
              onChange={(e) => setAssignData({ ...assignData, teacherId: e.target.value })}
              label="Teacher"
            >
              {teachers.map((teacher) => (
                <MenuItem key={teacher._id} value={teacher._id}>
                  {teacher.name} ({teacher.email})
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            fullWidth
            label="Demo Date"
            type="date"
            value={assignData.demoDate}
            onChange={(e) => setAssignData({ ...assignData, demoDate: e.target.value })}
            InputLabelProps={{ shrink: true }}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Demo Time"
            type="time"
            value={assignData.demoTime}
            onChange={(e) => setAssignData({ ...assignData, demoTime: e.target.value })}
            InputLabelProps={{ shrink: true }}
            sx={{ mb: 2 }}
          />
          <FormControl fullWidth>
            <InputLabel>Timezone</InputLabel>
            <Select
              value={assignData.timezone}
              onChange={(e) => setAssignData({ ...assignData, timezone: e.target.value })}
              label="Timezone"
            >
              {timezones.map((tz) => (
                <MenuItem key={tz} value={tz}>{tz}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAssignDialog(false)}>Cancel</Button>
          <Button 
            onClick={handleAssignTeacher}
            variant="contained" 
            color="primary"
            disabled={!assignData.teacherId || !assignData.demoDate || !assignData.demoTime}
          >
            Assign
          </Button>
        </DialogActions>
      </Dialog>

      {/* Reschedule Dialog */}
      <Dialog open={openRescheduleDialog} onClose={() => setOpenRescheduleDialog(false)} maxWidth="sm" fullWidth>
        <DialogTitle sx={{ bgcolor: 'primary.main', color: 'white' }}>Reschedule Demo</DialogTitle>
        <DialogContent sx={{ pt: 3 }}>
          <TextField
            fullWidth
            label="New Date"
            type="date"
            value={rescheduleData.newDate}
            onChange={(e) => setRescheduleData({ ...rescheduleData, newDate: e.target.value })}
            InputLabelProps={{ shrink: true }}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="New Time"
            type="time"
            value={rescheduleData.newTime}
            onChange={(e) => setRescheduleData({ ...rescheduleData, newTime: e.target.value })}
            InputLabelProps={{ shrink: true }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenRescheduleDialog(false)}>Cancel</Button>
          <Button 
            onClick={handleReschedule}
            variant="contained" 
            color="primary"
            disabled={!rescheduleData.newDate || !rescheduleData.newTime}
          >
            Reschedule
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default GetDemoFromWebsite;  