import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Snackbar,
  Container,
  CircularProgress, // Import CircularProgress for loading button
} from '@mui/material';
import baseUrl from '../../config';
import portrait from "../../Assets/portrait.png"; // Ensure this path is correct

const CreateCouponForm = () => {
  const [formData, setFormData] = useState({
    parentName: '',
    childName: '',
    customerEmail: '',
    courseType: '',
    price: 0,
    discountAmount: 0,
    currency: 'USD',
    discountPercentage: 5,  // Default discount is 5%
  });

  const [currencyRates, setCurrencyRates] = useState({
    INR: 0,
    SGD: 0,
    AUD: 0,
    QAR: 0,
    SAR: 0,
    GBP: 0,
    AED: 0,
  });

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [loading, setLoading] = useState(false);  // Loading state for button

  const coursePrices = {
    'BASIC PACKAGE': 1499,
    'PREMIUM': 1899,
    'FLAGSHIP': 2499,
  };

  // Apply the selected discount
  const applyDiscount = (price, discountPercentage) => {
    return price - (price * discountPercentage / 100);  // Apply discount as per selected percentage
  };

  useEffect(() => {
    const fetchCurrencyRates = async () => {
      try {
        const response = await axios.get('https://api.exchangerate-api.com/v4/latest/USD');
        setCurrencyRates(response.data.rates);
      } catch (error) {
        console.error('Error fetching currency rates:', error);
      }
    };

    fetchCurrencyRates();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'courseType') {
      const selectedPrice = coursePrices[value] || 0;
      const discounted = applyDiscount(selectedPrice, formData.discountPercentage);

      setSnackbarMessage(`You get a ${formData.discountPercentage}% discount on this course!`);
      setOpenSnackbar(true);

      setFormData((prevState) => ({
        ...prevState,
        courseType: value,
        price: selectedPrice,
        discountAmount: discounted,
      }));
    } else if (name === 'currency') {
      setFormData({
        ...formData,
        currency: value,
      });
    } else if (name === 'discountPercentage') {
      const discounted = applyDiscount(formData.price, value);
      setFormData({
        ...formData,
        discountPercentage: value,
        discountAmount: discounted,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const convertCurrency = (amount, currency) => {
    return (amount * currencyRates[currency]).toFixed(2);
  };

  const handleSubmit = async (e) => {
    const token = localStorage.getItem('token');

    e.preventDefault();

    setLoading(true);  // Set loading to true when form is submitted

    try {
      const response = await axios.post(`${baseUrl}api/sales/newcoupons`, {
        parentName: formData.parentName,
        childName: formData.childName,
        customerEmail: formData.customerEmail,
        courseType: formData.courseType,
        discountAmount: formData.discountAmount,
      },
      {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setSnackbarMessage('Coupon created successfully!');
        setOpenSnackbar(true);
      } else {
        setSnackbarMessage('Error creating coupon. Please try again.');
        setOpenSnackbar(true);
      }

      console.log('Coupon created successfully:', response.data);
    } catch (error) {
      console.error('Error creating coupon:', error);
      setSnackbarMessage('Error creating coupon. Please try again.');
      setOpenSnackbar(true);
    } finally {
      setLoading(false);  // Set loading to false when response is received or error occurs
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Container maxWidth="md">
      <Box
        sx={{
          maxWidth: 900,
          mx: "auto",
          p: 3,
          boxShadow: 3,
          borderRadius: 2,
          backgroundColor: "#fff",
        }}
      >
        <Typography variant="h4" sx={{ fontWeight: "bold", textAlign: "center", mb: 4 }}>
          Create Coupon
        </Typography>

        <Grid container spacing={4}>
          {/* Left Image Section */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                width: "100%",
                height: "100%",
                backgroundImage: `url(${portrait})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                borderRadius: 2,
                position: "relative",
              }}
            >
              <Box
                component="span"
                sx={{
                  position: "absolute",
                  top: 20,
                  right: 20,
                  fontSize: 50,
                  color: "white",
                }}
              >
                🎓
              </Box>
            </Box>
          </Grid>

          {/* Form Section */}
          <Grid item xs={12} md={6}>
            <Box component="form" onSubmit={handleSubmit} sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
              <TextField
                label="Parent Name"
                name="parentName"
                value={formData.parentName}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                sx={{ bgcolor: '#f8f8f8', borderRadius: 1 }}
              />
              <TextField
                label="Child Name"
                name="childName"
                value={formData.childName}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                sx={{ bgcolor: '#f8f8f8', borderRadius: 1 }}
              />
              <TextField
                label="Customer Email"
                name="customerEmail"
                value={formData.customerEmail}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                sx={{ bgcolor: '#f8f8f8', borderRadius: 1 }}
              />

              <FormControl fullWidth variant="outlined" sx={{ bgcolor: '#f8f8f8', borderRadius: 1 }}>
                <InputLabel>Course Type</InputLabel>
                <Select
                  name="courseType"
                  value={formData.courseType}
                  onChange={handleChange}
                  label="Course Type"
                >
                  <MenuItem value="BASIC PACKAGE">BASIC</MenuItem>
                  <MenuItem value="PREMIUM">PREMIUM</MenuItem>
                  <MenuItem value="FLAGSHIP">FLAGSHIP</MenuItem>
                </Select>
              </FormControl>

              <FormControl fullWidth variant="outlined" sx={{ bgcolor: '#f8f8f8', borderRadius: 1 }}>
                <InputLabel>Discount Percentage</InputLabel>
                <Select
                  name="discountPercentage"
                  value={formData.discountPercentage}
                  onChange={handleChange}
                  label="Discount Percentage"
                >
                  <MenuItem value={5}>5%</MenuItem>
                  <MenuItem value={10}>10%</MenuItem>
                  <MenuItem value={15}>15%</MenuItem>
                </Select>
              </FormControl>

              <FormControl fullWidth variant="outlined" sx={{ bgcolor: '#f8f8f8', borderRadius: 1 }}>
                <InputLabel>Currency</InputLabel>
                <Select
                  name="currency"
                  value={formData.currency}
                  onChange={handleChange}
                  label="Currency"
                >
                  <MenuItem value="USD">USD</MenuItem>
                  <MenuItem value="INR">INR</MenuItem>
                  <MenuItem value="SGD">SGD</MenuItem>
                  <MenuItem value="AUD">AUD</MenuItem>
                  <MenuItem value="QAR">QAR</MenuItem>
                  <MenuItem value="SAR">SAR</MenuItem>
                  <MenuItem value="GBP">GBP</MenuItem>
                  <MenuItem value="AED">AED</MenuItem>
                </Select>
              </FormControl>

              {formData.currency && formData.discountAmount > 0 && (
                <>
                  <TextField
                    label="Original Price"
                    value={formData.currency === 'USD' ? formData.price : convertCurrency(formData.price, formData.currency)}
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                    variant="outlined"
                    sx={{ bgcolor: '#f8f8f8', borderRadius: 1 }}
                  />
                  <TextField
                    label="Discounted Price"
                    value={formData.currency === 'USD' ? formData.discountAmount : convertCurrency(formData.discountAmount, formData.currency)}
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                    variant="outlined"
                    sx={{ bgcolor: '#f8f8f8', borderRadius: 1 }}
                  />
                </>
              )}

              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{
                  mt: 2,
                  backgroundColor: "darkred",
                  color: "white",
                  "&:hover": { backgroundColor: "red" },
                  borderRadius: 1,
                  textTransform: 'none',
                  fontWeight: 'bold',
                  padding: '12px 20px'
                }}
                disabled={loading} // Disable button while loading
              >
                {loading ? (
                  <CircularProgress size={24} sx={{ color: 'white' }} /> // Show loading spinner
                ) : (
                  'Create Coupon'
                )}
              </Button>
            </Box>
          </Grid>
        </Grid>

        <Snackbar
          open={openSnackbar}
          onClose={handleCloseSnackbar}
          message={snackbarMessage}
          autoHideDuration={5000}
        />
      </Box>
    </Container>
  );
};

export default CreateCouponForm;
