import React, { useState, useEffect } from 'react';
import {
  Box, Grid, Card, CardContent, Typography, Avatar,
  List, ListItem, ListItemText, ListItemAvatar, ListItemIcon,
  Chip, Divider, IconButton, Tooltip, Paper, LinearProgress,
  Rating, Badge, Button
} from '@mui/material';
import {
  School as SchoolIcon,
  CalendarMonth as CalendarMonthIcon,
  Person as PersonIcon,
  CheckCircle as CheckCircleIcon,
  Schedule as ScheduleIcon,
  ArrowBackIos as ArrowBackIosIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
  TrendingUp as TrendingUpIcon,
  Star as StarIcon
} from '@mui/icons-material';
import moment from 'moment-timezone';
import axios from 'axios';
import {
  
  Email as EmailIcon,
  Close as CloseIcon,

 
} from '@mui/icons-material';
const TeachersPage = ({ api, showSnackbar, handleApiError }) => {
  const [teachers, setTeachers] = useState([]);
  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const [teacherEngagement, setTeacherEngagement] = useState(null);
  const [teacherSchedule, setTeacherSchedule] = useState([]);
  const [teacherBookedSlots, setTeacherBookedSlots] = useState([]);
  const [availabilityRange, setAvailabilityRange] = useState({
    start: moment().startOf('day'),
    end: moment().add(7, 'days').endOf('day')
  });

  const fetchTeachers = async () => {
    try {
      const response = await api.get('api/newdemo/teachers');
      setTeachers(response.data);
    } catch (err) {
      handleApiError(err);
    }
  };

  const fetchTeacherEngagement = async (teacherId) => {
    try {
      const response = await api.get(`api/newdemo/teachers/${teacherId}/stats`);
      const stats = response.data;
      setTeacherEngagement({
        totalDemos: stats.totalDemos,
        completedDemos: stats.completedDemos,
        rescheduledDemos: stats.totalDemos - stats.completedDemos,
        completionRate: stats.completionRate / 100,
        avgRating: 4.5
      });
      setTeacherSchedule(stats.upcomingDemos);
    } catch (err) {
      handleApiError(err);
    }
  };

  const fetchTeacherBookedSlots = async (teacherId) => {
    try {
      const response = await api.get(`api/newdemo/teachers/${teacherId}/availability`, {
        params: {
          startDate: availabilityRange.start.format('YYYY-MM-DD'),
          endDate: availabilityRange.end.format('YYYY-MM-DD')
        }
      });
      setTeacherBookedSlots(response.data);
    } catch (err) {
      handleApiError(err);
      setTeacherBookedSlots([]);
    }
  };

  const calculateAvailability = (date) => {
    const dayStr = moment(date).format('YYYY-MM-DD');
    const dayBookings = teacherBookedSlots.filter(slot => slot.date === dayStr);

    const allHours = Array.from({ length: 24 }, (_, i) => 
      moment().hour(i).minute(0).format('HH:mm')
    );

    return allHours.filter(hour => {
      return !dayBookings.some(booking => {
        const bookingTime = moment(booking.time, 'HH:mm');
        const slotTime = moment(hour, 'HH:mm');
        
        return slotTime.isSameOrAfter(bookingTime) && 
               slotTime.isBefore(moment(bookingTime).add(1, 'hour'));
      });
    });
  };

  const handleWeekNavigation = (direction) => {
    const days = direction === 'next' ? 7 : -7;
    setAvailabilityRange({
      start: availabilityRange.start.clone().add(days, 'days'),
      end: availabilityRange.end.clone().add(days, 'days')
    });
    if (selectedTeacher) {
      fetchTeacherBookedSlots(selectedTeacher._id);
    }
  };

  const handleTeacherSelect = (teacher) => {
    setSelectedTeacher(teacher);
    fetchTeacherEngagement(teacher._id);
    fetchTeacherBookedSlots(teacher._id);
  };

  useEffect(() => {
    fetchTeachers();
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={4}>
        <Card sx={{
          borderRadius: 3,
          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.05)',
          height: "100vh",
          overflowY: 'auto',
          borderLeft: '4px solid #3f51b5',
          transition: 'all 0.3s ease',
          '&:hover': {
            boxShadow: '0px 6px 24px rgba(63, 81, 181, 0.15)'
          }
        }}>
          <CardContent sx={{ p: 0 }}>
            <Box sx={{
              p: 2,
              backgroundColor: '#f5f7ff',
              borderBottom: '1px solid #e0e0e0'
            }}>
              <Typography variant="h6" sx={{
                fontWeight: 600,
                color: 'primary.main',
                display: 'flex',
                alignItems: 'center',
                gap: 1
              }}>
                <SchoolIcon fontSize="medium" />
                Our Teaching Team
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {teachers.length} {teachers.length === 1 ? 'teacher' : 'teachers'} available
              </Typography>
            </Box>

            {teachers.length === 0 ? (
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                p: 4,
                textAlign: 'center'
              }}>
                <PersonIcon sx={{ 
                  fontSize: 48, 
                  color: 'text.disabled',
                  mb: 1 
                }} />
                <Typography variant="body1" color="text.secondary">
                  No teachers available
                </Typography>
              </Box>
            ) : (
              <List sx={{ p: 0 }}>
                {teachers.map((teacher) => (
                  <ListItem 
                    key={teacher._id}
                    button
                    selected={selectedTeacher?._id === teacher._id}
                    onClick={() => handleTeacherSelect(teacher)}
                    sx={{
                      p: 2,
                      borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
                      transition: 'all 0.2s ease',
                      '&:hover': {
                        backgroundColor: '#f5f7ff'
                      },
                      '&.Mui-selected': {
                        backgroundColor: '#e8f0fe',
                        '&:hover': {
                          backgroundColor: '#e0e8f6'
                        }
                      }
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar sx={{ 
                        bgcolor: selectedTeacher?._id === teacher._id ? 'primary.main' : 'primary.light',
                        color: selectedTeacher?._id === teacher._id ? '#fff' : 'primary.dark',
                        width: 40,
                        height: 40,
                        fontSize: '1.1rem',
                        fontWeight: 600
                      }}>
                        {teacher.name.charAt(0)}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Typography variant="subtitle1" sx={{ 
                          fontWeight: 600,
                          color: selectedTeacher?._id === teacher._id ? 'primary.main' : 'text.primary'
                        }}>
                          {teacher.name}
                        </Typography>
                      }
                      secondary={
                        <>
                          <Typography variant="body2" color="text.secondary" sx={{ 
                            display: 'flex',
                            alignItems: 'center',
                            gap: 0.5,
                            mt: 0.5
                          }}>
                            <EmailIcon fontSize="inherit" color="disabled" sx={{ fontSize: '1rem' }} />
                            {teacher.email}
                          </Typography>
                          <Box sx={{ 
                            display: 'flex',
                            alignItems: 'center',
                            mt: 1,
                            gap: 1.5
                          }}>
                            <Chip
                              icon={<ScheduleIcon />}
                              label={`${teacher.upcomingDemos?.length || 0} demos`}
                              size="small"
                              color={teacher.upcomingDemos?.length > 0 ? 'primary' : 'default'}
                              variant="outlined"
                              sx={{ 
                                borderRadius: 1,
                                fontSize: '0.75rem'
                              }}
                            />
                            {teacher.skills?.length > 0 && (
                              <Chip
                                label={teacher.skills.slice(0, 2).join(', ')}
                                size="small"
                                color="secondary"
                                variant="outlined"
                                sx={{ 
                                  borderRadius: 1,
                                  fontSize: '0.75rem',
                                  maxWidth: 120
                                }}
                              />
                            )}
                          </Box>
                        </>
                      }
                      sx={{ ml: 1 }}
                    />
                    {selectedTeacher?._id === teacher._id && (
                      <CheckCircleIcon color="primary" sx={{ ml: 1 }} />
                    )}
                  </ListItem>
                ))}
              </List>
            )}
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} md={8} sx={{ height: "100vh", overflowY: 'auto' }}>
        {selectedTeacher ? (
          <>
            {/* Teacher Engagement Stats */}
            <Card sx={{ 
              mb: 3, 
              borderRadius: 3,
              boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.05)',
              background: 'linear-gradient(to right, #f9f9ff, #ffffff)'
            }}>
              <CardContent>
                <Typography variant="h6" sx={{ 
                  mb: 3,
                  fontWeight: 600,
                  color: 'primary.main',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1
                }}>
                  <CheckCircleIcon fontSize="medium" />
                  Teacher Performance Metrics
                </Typography>
                
                <Grid container spacing={3}>
                  {/* Total Demos */}
                  <Grid item xs={12} sm={6} md={3}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Avatar sx={{ 
                        bgcolor: 'primary.light', 
                        width: 30, 
                        height: 30 
                      }}>
                        <ScheduleIcon color="primary" />
                      </Avatar>
                      <Box>
                        <Typography variant="subtitle2" color="text.secondary">
                          Total Demos
                        </Typography>
                        <Typography variant="h4" sx={{ fontWeight: 700 }}>
                          {teacherEngagement?.totalDemos || 0}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>

                  {/* Completed Demos */}
                  <Grid item xs={12} sm={6} md={3}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Avatar sx={{ 
                        bgcolor: 'success.light', 
                        width: 30, 
                        height: 30 
                      }}>
                        <CheckCircleIcon color="success" />
                      </Avatar>
                      <Box>
                        <Typography variant="subtitle2" color="text.secondary">
                          Completed
                        </Typography>
                        <Typography variant="h4" sx={{ fontWeight: 700, color: 'success.main' }}>
                          {teacherEngagement?.completedDemos || 0}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>

                  {/* Completion Rate */}
                  <Grid item xs={12} sm={6} md={3}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Avatar sx={{ 
                        bgcolor: 'warning.light', 
                        width: 30, 
                        height: 30 
                      }}>
                        <TrendingUpIcon color="warning" />
                      </Avatar>
                      <Box sx={{ width: '100%' }}>
                        <Typography variant="subtitle2" color="text.secondary">
                          Completion Rate
                        </Typography>
                        <LinearProgress 
                          variant="determinate" 
                          value={(teacherEngagement?.completionRate || 0) * 100} 
                          sx={{ 
                            height: 5, 
                            borderRadius: 5,
                            mb: 1,
                            backgroundColor: 'rgba(0, 0, 0, 0.05)'
                          }} 
                        />
                        <Typography sx={{ fontWeight: 700 }}>
                          {Math.round((teacherEngagement?.completionRate || 0) * 100)}%
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>

                  {/* Average Rating */}
                  <Grid item xs={12} sm={6} md={3}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Avatar sx={{ 
                        bgcolor: 'secondary.light', 
                        width: 30, 
                        height: 30 
                      }}>
                        <StarIcon color="secondary" />
                      </Avatar>
                      <Box>
                        <Typography variant="subtitle2" color="text.secondary">
                          Average Rating
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <Rating 
                            value={teacherEngagement?.avgRating || 0} 
                            precision={0.5} 
                            readOnly 
                            sx={{ color: '#ffb400' }}
                          />
                          <Typography variant="h6" sx={{ fontWeight: 700 }}>
                            {teacherEngagement?.avgRating?.toFixed(1) || '0.0'}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            {/* Teacher Schedule */}
            <Card sx={{ 
              borderRadius: 3,
              boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.05)',
              borderLeft: '4px solid #1976d2',
              mb: 3
            }}>
              <CardContent>
                <Typography variant="h6" sx={{ 
                  mb: 2,
                  fontWeight: 600,
                  color: 'primary.main',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1
                }}>
                  <CalendarMonthIcon fontSize="medium" />
                  Upcoming Demo Sessions
                </Typography>
                
                {teacherSchedule.length === 0 ? (
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    py: 3,
                    textAlign: 'center'
                  }}>
                    <ScheduleIcon sx={{ fontSize: 48, color: 'text.disabled', mb: 1 }} />
                    <Typography variant="body1" color="text.secondary">
                      No upcoming demo sessions scheduled
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                      All time slots are currently available
                    </Typography>
                  </Box>
                ) : (
                  <List sx={{ p: 0 }}>
                    {teacherSchedule.map((demo) => {
                      const isToday = moment(demo.demoDateIndia).isSame(moment(), 'day');
                      return (
                        <ListItem 
                          key={demo._id}
                          sx={{
                            p: 2,
                            mb: 1,
                            borderRadius: 2,
                            backgroundColor: isToday ? '#f5f8ff' : 'inherit',
                            borderLeft: isToday ? '3px solid #1976d2' : 'none',
                            transition: 'all 0.2s ease',
                            '&:hover': {
                              backgroundColor: '#f9f9f9',
                              transform: 'translateY(-2px)'
                            }
                          }}
                        >
                          <ListItemIcon sx={{ minWidth: 40 }}>
                            <Avatar sx={{ 
                              bgcolor: isToday ? 'primary.light' : 'action.hover',
                              width: 36, 
                              height: 36 
                            }}>
                              <CalendarMonthIcon 
                                fontSize="small" 
                                color={isToday ? 'primary' : 'action'} 
                              />
                            </Avatar>
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                                {demo.childName} ({demo.gradeAge})
                              </Typography>
                            }
                            secondary={
                              <>
                                <Box component="span" sx={{ display: 'block' }}>
                                  <Typography 
                                    component="span" 
                                    variant="body2"
                                    color={isToday ? 'primary.main' : 'text.primary'}
                                    sx={{ fontWeight: 500 }}
                                  >
                                    {moment(demo.demoDateIndia).format('ddd, MMM D • h:mm A')}
                                  </Typography>
                                </Box>
                                <Box component="span" sx={{ display: 'block' }}>
                                  <Typography 
                                    component="span" 
                                    variant="body2" 
                                    color="text.secondary"
                                  >
                                    {demo.parentName} • {demo.customerEmail}
                                  </Typography>
                                </Box>
                              </>
                            }
                            sx={{ ml: 1 }}
                          />
                          <Chip 
                            label={demo.status}
                            size="small"
                            sx={{
                              fontWeight: 600,
                              fontSize: '0.75rem',
                              textTransform: 'capitalize',
                              minWidth: 90,
                              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)'
                            }}
                            color={
                              demo.status === 'completed' ? 'success' :
                              demo.status === 'rescheduled' ? 'warning' : 
                              demo.status === 'scheduled' ? 'primary' : 'default'
                            }
                          />
                        </ListItem>
                      );
                    })}
                  </List>
                )}
              </CardContent>
            </Card>

            {/* Teacher Availability */}
            <Card sx={{ mt: 3, borderRadius: 3, boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.08)' }}>
              <CardContent>
                <Box sx={{ 
                  display: 'flex', 
                  justifyContent: 'space-between', 
                  alignItems: 'center', 
                  mb: 3,
                  p: 1,
                  backgroundColor: '#f5f7fa',
                  borderRadius: 2
                }}>
                  <Typography variant="h6" sx={{ 
                    fontWeight: 600, 
                    color: 'primary.main',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1
                  }}>
                    <ScheduleIcon fontSize="medium" />
                    Teacher Availability ({availabilityRange.start.format('MMM D')} - {availabilityRange.end.format('MMM D')})
                  </Typography>
                  <Box>
                    <Tooltip title="Previous week">
                      <IconButton 
                        onClick={() => handleWeekNavigation('prev')}
                        sx={{ 
                          backgroundColor: 'primary.light', 
                          color: 'primary.main',
                          '&:hover': { backgroundColor: 'primary.main', color: 'white' },
                          mr: 1
                        }}
                      >
                        <ArrowBackIosIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Next week">
                      <IconButton 
                        onClick={() => handleWeekNavigation('next')}
                        sx={{ 
                          backgroundColor: 'primary.light', 
                          color: 'primary.main',
                          '&:hover': { backgroundColor: 'primary.main', color: 'white' }
                        }}
                      >
                        <ArrowForwardIosIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>

                <Grid container spacing={2}>
                  {Array.from({ length: 7 }).map((_, index) => {
                    const date = availabilityRange.start.clone().add(index, 'days');
                    const dayStr = date.format('YYYY-MM-DD');
                    const dayBookings = teacherBookedSlots.filter(slot => slot.date === dayStr);
                    const availableSlots = calculateAvailability(date);
                    const isToday = date.isSame(moment(), 'day');
                    
                    return (
                      <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                        <Paper elevation={2} sx={{ 
                          p: 2, 
                          height: '100%',
                          border: isToday ? '2px solid #1976d2' : 'none',
                          borderRadius: 2,
                          position: 'relative',
                          overflow: 'hidden',
                          '&:hover': {
                            boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.1)'
                          }
                        }}>
                          {isToday && (
                            <Box sx={{
                              position: 'absolute',
                              top: 0,
                              right: 0,
                              backgroundColor: 'primary.main',
                              color: 'white',
                              px: 1,
                              py: 0.5,
                              fontSize: '0.75rem',
                              borderBottomLeftRadius: 8
                            }}>
                              Today
                            </Box>
                          )}
                          
                          <Typography variant="subtitle1" sx={{ 
                            fontWeight: 600, 
                            mb: 1,
                            color: isToday ? 'primary.main' : 'text.primary',
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1
                          }}>
                            <CalendarMonthIcon fontSize="small" />
                            {date.format('dddd, MMM D')}
                          </Typography>
                          
                          <Divider sx={{ mb: 2 }} />

                          {/* Booked Slots Section */}
                          {dayBookings.length > 0 && (
                            <Box sx={{ mb: 2 }}>
                              <Typography variant="body2" sx={{ 
                                mb: 1, 
                                fontWeight: 500,
                                color: 'text.secondary',
                                display: 'flex',
                                alignItems: 'center',
                                gap: 0.5
                              }}>
                                <CheckCircleIcon fontSize="small" color="disabled" />
                                Booked Sessions
                              </Typography>
                              <Box sx={{ 
                                display: 'flex', 
                                flexWrap: 'wrap', 
                                gap: 1,
                                mb: 2
                              }}>
                                {dayBookings.map(booking => (
                                  <Chip
                                    key={booking.time}
                                    label={`${booking.time}`}
                                    color="default"
                                    variant="outlined"
                                    size="small"
                                    sx={{ 
                                      cursor: 'not-allowed',
                                      opacity: 0.8,
                                      '& .MuiChip-label': {
                                        fontSize: '0.75rem'
                                      }
                                    }}
                                    icon={<ScheduleIcon fontSize="small" />}
                                  />
                                ))}
                              </Box>
                            </Box>
                          )}

                          {/* Available Slots Section */}
                          <Box>
                            <Typography variant="body2" sx={{ 
                              mb: 1, 
                              fontWeight: 500,
                              color: availableSlots.length > 0 ? 'success.main' : 'text.secondary',
                              display: 'flex',
                              alignItems: 'center',
                              gap: 0.5
                            }}>
                              {availableSlots.length > 0 ? (
                                <>
                                  <ScheduleIcon fontSize="small" color="success" />
                                  Available Slots ({availableSlots.length})
                                </>
                              ) : (
                                <>
                                  <CloseIcon fontSize="small" color="error" />
                                  No availability
                                </>
                              )}
                            </Typography>
                            
                            <Box sx={{ 
                              display: 'flex',
                              flexWrap: 'wrap',
                              gap: 1,
                              maxHeight: 200,
                              overflowY: 'auto',
                              p: 1,
                              backgroundColor: '#f9f9f9',
                              borderRadius: 1
                            }}>
                              {availableSlots.length > 0 ? (
                                availableSlots.map(slot => (
                                  <Chip
                                    key={slot}
                                    label={slot}
                                    color="primary"
                                    variant="filled"
                                    size="small"
                                    sx={{ 
                                      cursor: 'pointer',
                                      '&:hover': {
                                        backgroundColor: 'primary.dark',
                                        color: 'white'
                                      },
                                      '& .MuiChip-label': {
                                        fontWeight: 500,
                                        fontSize: '0.8rem'
                                      }
                                    }}
                                  />
                                ))
                              ) : (
                                <Typography variant="caption" color="text.secondary" sx={{ fontStyle: 'italic' }}>
                                  All slots are booked for this day
                                </Typography>
                              )}
                            </Box>
                          </Box>
                        </Paper>
                      </Grid>
                    );
                  })}
                </Grid>
              </CardContent>
            </Card>
          </>
        ) : (
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            height: 300 
          }}>
            <Typography color="text.secondary">
              Select a teacher to view details
            </Typography>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default TeachersPage;