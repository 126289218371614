import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  CircularProgress,
  Snackbar,
  Container,
  Chip,
  Divider,
  Paper,
} from '@mui/material';
import { format } from 'date-fns';
import baseUrl from '../../config';
import { FaRegCreditCard } from 'react-icons/fa'; // Example icon
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const GetEmpCoupon = () => {
  const [coupons, setCoupons] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const navigate = useNavigate(); // Initialize navigate

  useEffect(() => {
    const fetchCoupons = async () => {
      setLoading(true);
      setError('');
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${baseUrl}api/sales/coupons`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        setCoupons(response.data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        setError('Failed to fetch coupons. Please try again.');
        setSnackbarMessage('Failed to fetch coupons.');
        setOpenSnackbar(true);
      }
    };

    fetchCoupons();
  }, []);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  // Check if the coupon is active based on expiration time and isActive flag
  const isCouponActive = (expirationTime, isActive) => {
    const now = new Date();
    return isActive && new Date(expirationTime) > now;
  };

  return (
    <Container maxWidth="lg">
      <Box sx={{ py: 4 }}>
        <Typography variant="h4" sx={{ fontWeight: 'bold', textAlign: 'center', mb: 4 }}>
          Your Coupons
        </Typography>

        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress size={60} color="primary" />
          </Box>
        ) : error ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', color: 'error.main' }}>
            <Typography variant="h6">{error}</Typography>
          </Box>
        ) : (
          <Grid container spacing={4}>
            {coupons.length === 0 ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <Typography variant="h6" color="textSecondary">
                  No coupons found.
                </Typography>
              </Box>
            ) : (
              coupons.map((coupon) => {
                const active = isCouponActive(coupon.expirationTime, coupon.isActive);
                return (
                  <Grid item xs={12} sm={6} md={4} key={coupon._id}>
                    <Card
                      sx={{
                        maxWidth: 345,
                        boxShadow: 3,
                        bgcolor: active ? 'white' : 'grey.200',
                        borderRadius: 2,
                        '&:hover': {
                          boxShadow: 12,
                          transform: 'scale(1.03)',
                          transition: '0.3s',
                        },
                        padding: 2,
                      }}
                    >
                      <CardContent sx={{ padding: '16px !important' }}>
                        {/* Coupon Header */}
                        <Box sx={{ textAlign: 'center' }}>
                          <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#781E3F' }}>
                            {coupon.courseType}
                          </Typography>
                          <Divider sx={{ my: 1 }} />
                        </Box>

                        {/* Coupon Details */}
                        <Box sx={{ textAlign: 'left' }}>
                          <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                            <strong>Customer Email:</strong> {coupon.customerEmail}
                          </Typography>
                          <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                            <strong>Parent Name:</strong> {coupon.parentName}
                          </Typography>
                          <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                            <strong>Child Name:</strong> {coupon.childName}
                          </Typography>
                          <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                            <strong>Discount Amount:</strong> ${coupon.discountAmount.toFixed(2)}
                          </Typography>

                          {/* Coupon Status Chip */}
                          <Box sx={{ my: 2 }}>
                            <Chip
                              label={active ? 'Active' : 'Expired'}
                              color={active ? 'success' : 'error'}
                              sx={{
                                textTransform: 'capitalize',
                                fontWeight: 'bold',
                                fontSize: 14,
                                borderRadius: 1,
                              }}
                            />
                          </Box>

                          <Typography variant="body2" color="textSecondary">
                            <strong>Expiration Time:</strong> {format(new Date(coupon.expirationTime), 'MM/dd/yyyy HH:mm')}
                          </Typography>

                          {/* Coupon Code Section */}
                          <Box sx={{ textAlign: 'center', my: 2 }}>
                            <Button
                              sx={{
                                bgcolor: 'primary.main',
                                color: 'white',
                                '&:hover': {
                                  bgcolor: 'primary.dark',
                                },
                                borderRadius: 3,
                                fontWeight: 'bold',
                                padding: '10px 20px',
                              }}
                              variant="contained"
                              onClick={() => {
                                // Open the coupon details page in a new tab with coupon code
                                window.open(`/coupon/${coupon.couponCode}`, '_blank');
                              }}
                            >
                              <FaRegCreditCard style={{ marginRight: '8px' }} />
                              Show Coupon Code
                            </Button>
                          </Box>
                        </Box>

                        <Divider sx={{ my: 1 }} />
                        <Box sx={{ textAlign: 'center' }}>
                          <Typography variant="body2" color="textSecondary">
                            Use this coupon to get a discount on your next purchase!
                          </Typography>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                );
              })
            )}
          </Grid>
        )}
      </Box>

      <Snackbar
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        autoHideDuration={5000}
      />
    </Container>
  );
};

export default GetEmpCoupon;
