import React, { useState } from 'react';
import {
  Button,
  TextField,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Grid,
  Snackbar,
  Alert,
  Container,
  Box,
} from '@mui/material';
import moment from 'moment-timezone';
import baseUrl from '../../config';

const timezones = moment.tz.names();
const regions = ['North America', 'RoW', 'India', 'Other'];

const DemoBookingForm = () => {
  const [formData, setFormData] = useState({
    parentName: '',
    childName: '',
    gradeAge: '',
    timezone: '', // Parent's timezone (to be selected)
    demoDate: '', // Parent's local date
    demoTime: '', // Parent's local time
    customerEmail: '',
    customerPhone: '',
    customerAlternatePhone: '',
    region: '',
  });

  const [istTime, setIstTime] = useState(''); // For salesperson to see IST conversion
  const [errors, setErrors] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  // Update IST conversion whenever time-related fields change
  const updateIstTime = () => {
    if (formData.timezone && formData.demoDate && formData.demoTime) {
      const parentDateTime = moment.tz(
        `${formData.demoDate} ${formData.demoTime}`,
        'YYYY-MM-DD HH:mm',
        formData.timezone
      );
      const istDateTime = parentDateTime.clone().tz('Asia/Kolkata');
      setIstTime(istDateTime.format('YYYY-MM-DD HH:mm (IST)'));
    } else {
      setIstTime('');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => {
      const newData = { ...prev, [name]: value };
      return newData;
    });
    
    // Update IST time when relevant fields change
    if (['timezone', 'demoDate', 'demoTime'].includes(name)) {
      updateIstTime();
    }
    
    setErrors({ ...errors, [name]: '' });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.parentName.trim()) newErrors.parentName = 'Parent name is required';
    if (!formData.childName.trim()) newErrors.childName = 'Child name is required';
    if (!formData.gradeAge.trim()) newErrors.gradeAge = 'Grade/Age is required';
    if (!formData.timezone) newErrors.timezone = 'Timezone is required';
    if (!formData.demoDate) newErrors.demoDate = 'Date is required';
    if (!formData.demoTime.match(/^([01]\d|2[0-3]):([0-5]\d)$/)) newErrors.demoTime = 'Invalid time format (HH:mm)';
    if (!formData.customerEmail.match(/.+\@.+\..+/)) newErrors.customerEmail = 'Invalid email';
    if (!formData.customerPhone.match(/^\+?[1-9]\d{1,14}$/)) newErrors.customerPhone = 'Invalid phone';
    if (formData.customerAlternatePhone && !formData.customerAlternatePhone.match(/^\+?[1-9]\d{1,14}$/)) {
      newErrors.customerAlternatePhone = 'Invalid phone';
    }
    if (!formData.region) newErrors.region = 'Region is required';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const prepareSubmissionData = () => {
    // Convert parent's time to IST for backend
    const parentDateTime = moment.tz(
      `${formData.demoDate} ${formData.demoTime}`,
      'YYYY-MM-DD HH:mm',
      formData.timezone
    );
    const istDateTime = parentDateTime.clone().tz('Asia/Kolkata');

    return {
      ...formData,
      demoDateIndia: istDateTime.format('YYYY-MM-DDTHH:mm:ss'), // ISO format in IST
      demoSlotIST: istDateTime.format('HH:mm'), // Time in IST
    };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      const submissionData = prepareSubmissionData();
      const token = localStorage.getItem('token');
      const response = await fetch(baseUrl + 'api/newdemo/book', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(submissionData),
      });

      if (!response.ok) throw new Error('Booking failed');

      setSnackbarMessage('Demo booked successfully!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      
      // Reset form
      setFormData({
        parentName: '',
        childName: '',
        gradeAge: '',
        timezone: '',
        demoDate: '',
        demoTime: '',
        customerEmail: '',
        customerPhone: '',
        customerAlternatePhone: '',
        region: '',
      });
      setIstTime('');
    } catch (error) {
      setSnackbarMessage(error.message || 'Error booking demo');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom align="center">
        Book Demo for Parent
      </Typography>
      
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          {/* Parent Information */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Parent Name"
              name="parentName"
              value={formData.parentName}
              onChange={handleChange}
              error={!!errors.parentName}
              helperText={errors.parentName}
            />
          </Grid>
          
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Child Name"
              name="childName"
              value={formData.childName}
              onChange={handleChange}
              error={!!errors.childName}
              helperText={errors.childName}
            />
          </Grid>
          
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Grade/Age"
              name="gradeAge"
              value={formData.gradeAge}
              onChange={handleChange}
              error={!!errors.gradeAge}
              helperText={errors.gradeAge}
            />
          </Grid>
          
          {/* Parent's Timezone */}
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth error={!!errors.timezone}>
              <InputLabel>Parent's Timezone</InputLabel>
              <Select
                name="timezone"
                value={formData.timezone}
                onChange={handleChange}
                label="Parent's Timezone"
              >
                {timezones.map(tz => (
                  <MenuItem key={tz} value={tz}>
                    {tz} ({moment.tz(tz).format('Z')})
                  </MenuItem>
                ))}
              </Select>
              {errors.timezone && <Typography color="error">{errors.timezone}</Typography>}
            </FormControl>
          </Grid>
          
          {/* Parent's Local Time */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Demo Date (Parent's Local Date)"
              type="date"
              name="demoDate"
              value={formData.demoDate}
              onChange={handleChange}
              error={!!errors.demoDate}
              helperText={errors.demoDate}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Demo Time (Parent's Local Time)"
              name="demoTime"
              value={formData.demoTime}
              onChange={handleChange}
              error={!!errors.demoTime}
              helperText={errors.demoTime || "Format: HH:mm (24-hour)"}
              placeholder="14:30"
            />
          </Grid>
          
          {/* Display IST Time for Salesperson */}
          {istTime && (
            <Grid item xs={12}>
              <Box bgcolor="#f5f5f5" p={2} borderRadius={1}>
                <Typography>
                  <strong>IST Time:</strong> {istTime}
                </Typography>
              </Box>
            </Grid>
          )}
          
          {/* Contact Information */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Email"
              name="customerEmail"
              value={formData.customerEmail}
              onChange={handleChange}
              error={!!errors.customerEmail}
              helperText={errors.customerEmail}
            />
          </Grid>
          
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Phone Number"
              name="customerPhone"
              value={formData.customerPhone}
              onChange={handleChange}
              error={!!errors.customerPhone}
              helperText={errors.customerPhone}
            />
          </Grid>
          
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Alternate Phone"
              name="customerAlternatePhone"
              value={formData.customerAlternatePhone}
              onChange={handleChange}
              error={!!errors.customerAlternatePhone}
              helperText={errors.customerAlternatePhone}
            />
          </Grid>
          
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth error={!!errors.region}>
              <InputLabel>Region</InputLabel>
              <Select
                name="region"
                value={formData.region}
                onChange={handleChange}
                label="Region"
              >
                {regions.map(region => (
                  <MenuItem key={region} value={region}>{region}</MenuItem>
                ))}
              </Select>
              {errors.region && <Typography color="error">{errors.region}</Typography>}
            </FormControl>
          </Grid>
          
          <Grid item xs={12}>
            <Button 
              type="submit" 
              variant="contained" 
              color="primary" 
              fullWidth
              size="large"
            >
              Book Demo
            </Button>
          </Grid>
        </Grid>
      </form>
      
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert 
          severity={snackbarSeverity} 
          onClose={() => setSnackbarOpen(false)}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default DemoBookingForm;