import React, { useState } from "react";
import { TextField, Button, MenuItem, Select, InputLabel, FormControl, Box, Typography } from "@mui/material";
import axios from "axios";
import baseUrl from "../../config";

const CouponForm = () => {
  const [discountPercent, setDiscountPercent] = useState("");
  const [expirationDuration, setExpirationDuration] = useState("");
  const [message, setMessage] = useState("");
  const [coupon, setCoupon] = useState(null);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const response = await axios.post(`${baseUrl}api/demo/createCoupon`,
        
        { discountPercent, expirationDuration });
      setCoupon(response.data.coupon);
      setMessage(response.data.message);
    } catch (error) {
      setMessage(error.response?.data?.message || "Something went wrong");
    }
  };

  return (
    <Box sx={{ maxWidth: 500, margin: "auto", padding: 3 }}>
      <Typography variant="h4" align="center" gutterBottom>Create Coupon</Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          select
          label="Discount Percentage"
          fullWidth
          value={discountPercent}
          onChange={(e) => setDiscountPercent(e.target.value)}
          required
          sx={{ marginBottom: 2 }}
        >
          {[5, 10, 15, 20].map((discount) => (
            <MenuItem key={discount} value={discount}>
              {discount}%
            </MenuItem>
          ))}
        </TextField>

        <FormControl fullWidth required sx={{ marginBottom: 2 }}>
          <InputLabel>Expiration Duration</InputLabel>
          <Select
            value={expirationDuration}
            onChange={(e) => setExpirationDuration(e.target.value)}
            label="Expiration Duration"
          >
            {['1 hour', '2 hours', '1 day', '15 days', '30 days', '1 year'].map((duration) => (
              <MenuItem key={duration} value={duration}>
                {duration}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button type="submit" variant="contained" color="primary" fullWidth>
          Create Coupon
        </Button>
      </form>

      {message && <Typography variant="body2" color="textSecondary" mt={2}>{message}</Typography>}

      {coupon && (
        <Box mt={3} sx={{ backgroundColor: "#f5f5f5", padding: 2 }}>
          <Typography variant="h6">Coupon Created:</Typography>
          <Typography>Code: {coupon.code}</Typography>
          <Typography>Discount: {coupon.discount}%</Typography>
          <Typography>Expires: {coupon.expirationDate}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default CouponForm;
