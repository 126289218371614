import React, { useState, useEffect } from 'react';
import { Box, Tabs, Tab, Snackbar, Alert,Typography } from '@mui/material';
import axios from 'axios';
import baseUrl from '../../config';
import TeachersPage from './TeachersPage';
import GetBookingsPage from './GetBookingsPage';
import Analytics from './GetAnalytic';

// Set up axios instance with base URL and auth token
const api = axios.create({
  baseURL: baseUrl
});

// Add request interceptor to include auth token
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const OperationDashboard = () => {
  const [activeTab, setActiveTab] = useState('bookings');
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  const showSnackbar = (message, severity) => {
    setSnackbar({ open: true, message, severity });
  };

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleApiError = (error) => {
    console.error('API Error:', error);
    let errorMessage = 'An error occurred';
    if (error.response) {
      if (error.response.status === 401) {
        errorMessage = 'Session expired. Please login again.';
      } else if (error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      }
    } else if (error.message) {
      errorMessage = error.message;
    }
    showSnackbar(errorMessage, 'error');
    return errorMessage;
  };

  return (
    <>
      {/* Tabs Navigation */}
      <Box sx={{ borderBottom: 1, borderColor: 'divider',  }}>
        <Tabs 
          value={activeTab} 
          onChange={(e, newValue) => setActiveTab(newValue)}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Bookings" value="bookings" />
          <Tab label="Teachers" value="teachers" />
          <Tab label="Analytics" value="analytics" />
        </Tabs>
      </Box>

      {/* Bookings Tab */}
      {activeTab === 'bookings' && (
        <GetBookingsPage
          api={api} 
          showSnackbar={showSnackbar} 
          handleApiError={handleApiError} 
        />
      )}

      {/* Teachers Tab */}
      {activeTab === 'teachers' && (
        <TeachersPage 
          api={api} 
          showSnackbar={showSnackbar} 
          handleApiError={handleApiError} 
        />
      )}

      {/* Analytics Tab */}
      {activeTab === 'analytics' && (
        <Box>
          {/* <Typography variant="h6" gutterBottom>Coming Soon</Typography>
          <Typography color="text.secondary">
            Analytics dashboard with demo conversion rates, teacher performance, and more.
          </Typography> */}
          <Analytics/>
        </Box>
      )}

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert 
          onClose={handleSnackbarClose} 
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default OperationDashboard;