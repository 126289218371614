import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  Grid, 
  Card, 
  CardContent, 
  CircularProgress,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Paper,
  TextField
} from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import axios from 'axios';
import moment from 'moment';
import baseUrl from '../../config';

const Analytics = () => {
  const [loading, setLoading] = useState(true);
  const [analyticsData, setAnalyticsData] = useState({
    total: 0,
    byStatus: {},
    byRegion: {},
    conversionRate: 0
  });
  const [dateRange, setDateRange] = useState({
    startDate: moment().subtract(30, 'days'),
    endDate: moment()
  });
  const [regionFilter, setRegionFilter] = useState('all');

  useEffect(() => {
    fetchAnalyticsData();
  }, [dateRange, regionFilter]);

  const fetchAnalyticsData = async () => {
    const token = localStorage.getItem('token');
    try {
      setLoading(true);
      const params = {
        startDate: dateRange.startDate.format('YYYY-MM-DD'),
        endDate: dateRange.endDate.format('YYYY-MM-DD'),
        region: regionFilter !== 'all' ? regionFilter : undefined
      };

      const response = await axios.get(baseUrl + '/api/newdemo/analytics', {
        params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setAnalyticsData(response.data || {
        total: 0,
        byStatus: {},
        byRegion: {},
        conversionRate: 0
      });
      setLoading(false);
    } catch (error) {
      console.error('Error fetching analytics:', error);
      setAnalyticsData({
        total: 0,
        byStatus: {},
        byRegion: {},
        conversionRate: 0
      });
      setLoading(false);
    }
  };

  const handleDateChange = (name, date) => {
    setDateRange(prev => ({
      ...prev,
      [name]: date
    }));
  };

  const formatChartData = () => {
    // Default empty data if analyticsData is not loaded
    if (!analyticsData || loading) {
      return {
        statusData: [],
        regionData: []
      };
    }
    
    // Status data for bar chart
    const statusData = analyticsData.byStatus 
      ? Object.entries(analyticsData.byStatus).map(([status, count]) => ({
          name: status.charAt(0).toUpperCase() + status.slice(1),
          count
        }))
      : [];

    // Region data for bar chart
    const regionData = analyticsData.byRegion 
      ? Object.entries(analyticsData.byRegion).map(([region, count]) => ({
          name: region,
          count
        }))
      : [];

    return { statusData, regionData };
  };

  const { statusData, regionData } = formatChartData();

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Demo Analytics Dashboard
      </Typography>
      
      {/* Filters */}
      <Paper sx={{ p: 2, mb: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth>
              <InputLabel>Region</InputLabel>
              <Select
                value={regionFilter}
                label="Region"
                onChange={(e) => setRegionFilter(e.target.value)}
              >
                <MenuItem value="all">All Regions</MenuItem>
                <MenuItem value="north">North</MenuItem>
                <MenuItem value="south">South</MenuItem>
                <MenuItem value="east">East</MenuItem>
                <MenuItem value="west">West</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Paper>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {/* Summary Cards */}
          <Grid container spacing={3} sx={{ mb: 3 }}>
            <Grid item xs={12} sm={6} md={3}>
              <Card>
                <CardContent>
                  <Typography color="text.secondary" gutterBottom>
                    Total Demos
                  </Typography>
                  <Typography variant="h4">
                    {analyticsData.total || 0}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Card>
                <CardContent>
                  <Typography color="text.secondary" gutterBottom>
                    Conversion Rate
                  </Typography>
                  <Typography variant="h4">
                    {analyticsData.conversionRate || 0}%
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Card>
                <CardContent>
                  <Typography color="text.secondary" gutterBottom>
                    Completed Demos
                  </Typography>
                  <Typography variant="h4">
                    {(analyticsData.byStatus && analyticsData.byStatus.completed) || 0}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Card>
                <CardContent>
                  <Typography color="text.secondary" gutterBottom>
                    Cancelled Demos
                  </Typography>
                  <Typography variant="h4">
                    {(analyticsData.byStatus && analyticsData.byStatus.cancelled) || 0}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          {/* Charts */}
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Demo Status Distribution
                  </Typography>
                  <Box sx={{ height: 400 }}>
                    {statusData.length > 0 ? (
                      <ResponsiveContainer width="100%" height="100%">
                        <BarChart
                          data={statusData}
                          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="name" />
                          <YAxis />
                          <Tooltip />
                          <Legend />
                          <Bar dataKey="count" fill="#8884d8" name="Count" />
                        </BarChart>
                      </ResponsiveContainer>
                    ) : (
                      <Typography>No status data available</Typography>
                    )}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Regional Distribution
                  </Typography>
                  <Box sx={{ height: 400 }}>
                    {regionData.length > 0 ? (
                      <ResponsiveContainer width="100%" height="100%">
                        <BarChart
                          data={regionData}
                          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="name" />
                          <YAxis />
                          <Tooltip />
                          <Legend />
                          <Bar dataKey="count" fill="#82ca9d" name="Count" />
                        </BarChart>
                      </ResponsiveContainer>
                    ) : (
                      <Typography>No regional data available</Typography>
                    )}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          {/* Data Table (Optional) */}
          <Box sx={{ mt: 3 }}>
            <Typography variant="h6" gutterBottom>
              Detailed Statistics
            </Typography>
            <Paper sx={{ p: 2 }}>
              <Typography>
                <strong>Top Regions:</strong> 
                {analyticsData.byRegion && Object.keys(analyticsData.byRegion).length > 0
                  ? Object.entries(analyticsData.byRegion)
                      .sort((a, b) => b[1] - a[1])
                      .slice(0, 3)
                      .map(([region, count]) => `${region} (${count})`)
                      .join(', ')
                  : 'No regional data available'}
              </Typography>
              <Typography sx={{ mt: 1 }}>
                <strong>Success Rate:</strong> {analyticsData.conversionRate || 0}% of demos converted to completed status
              </Typography>
              <Typography sx={{ mt: 1 }}>
                <strong>Time Period:</strong> {dateRange.startDate.format('MMM D, YYYY')} - {dateRange.endDate.format('MMM D, YYYY')}
              </Typography>
            </Paper>
          </Box>
        </>
      )}
    </Box>
  );
};

export default Analytics;