import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Snackbar,
  Alert,
  CircularProgress,
  IconButton,
  Tooltip,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Chip,
  Grid,
  Card,
  Menu
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import baseUrl from '../../config';
import { 
  Refresh, 
  ErrorOutline, 
  FilterList,
  Clear,
  Bookmark,
  BookmarkBorder,
  Email as EmailIcon,
  Message as MessageIcon
} from '@mui/icons-material';

const GetBookings = () => {
  const [bookings, setBookings] = useState([]); // This is already correct in your code  const [loading, setLoading] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [messageDialogOpen, setMessageDialogOpen] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [customMessage, setCustomMessage] = useState('');
  const [sendingMessage, setSendingMessage] = useState(false);
  const [loading, setLoading] = useState('');

  const theme = useTheme();

  // Filter state
  const [filters, setFilters] = useState({
    status: [],
    dateFrom: null,
    dateTo: null,
    salesPersonId: '',
    region: [],
    interested: null,
    search: ''
  });

  // Saved filters
  const [savedFilters, setSavedFilters] = useState([]);
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);
  const [filterName, setFilterName] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    fetchBookings();
    // Load saved filters from localStorage
    const saved = localStorage.getItem('savedBookingFilters');
    if (saved) {
      setSavedFilters(JSON.parse(saved));
    }
  }, []);

  const fetchBookings = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      
      // Convert filters to query params
      const params = new URLSearchParams();
      
      if (filters.status.length > 0) {
        params.append('status', filters.status.join(','));
      }
      
      if (filters.dateFrom) {
        params.append('dateFrom', moment(filters.dateFrom).format('YYYY-MM-DD'));
      }
      
      if (filters.dateTo) {
        params.append('dateTo', moment(filters.dateTo).format('YYYY-MM-DD'));
      }
      
      if (filters.salesPersonId) {
        params.append('salesPersonId', filters.salesPersonId);
      }
      
      if (filters.region.length > 0) {
        params.append('region', filters.region.join(','));
      }
      
      if (filters.interested !== null) {
        params.append('interested', filters.interested.toString());
      }
      
      if (filters.search) {
        params.append('search', filters.search);
      }

      const response = await fetch(`${baseUrl}api/newdemo/bookings?${params.toString()}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const { data } = await response.json();
      if (!Array.isArray(data)) {
        throw new Error('Invalid data format received from server');
      }
      setBookings(data || []);
    } catch (error) {
      setSnackbarMessage(error.message || 'An error occurred');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleFilterChange = (name, value) => {
    setFilters(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const resetFilters = () => {
    setFilters({
      status: [],
      dateFrom: null,
      dateTo: null,
      salesPersonId: '',
      region: [],
      interested: null,
      search: ''
    });
    fetchBookings();
  };

  const handleSaveFilter = () => {
    if (!filterName.trim()) {
      setSnackbarMessage('Please enter a filter name');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }
    
    const newFilter = {
      name: filterName,
      filters: filters,
      createdAt: new Date()
    };
    
    const updatedSavedFilters = [...savedFilters, newFilter];
    setSavedFilters(updatedSavedFilters);
    localStorage.setItem('savedBookingFilters', JSON.stringify(updatedSavedFilters));
    
    setFilterName('');
    setSaveDialogOpen(false);
    setSnackbarMessage('Filter saved successfully!');
    setSnackbarSeverity('success');
    setSnackbarOpen(true);
  };

  const applySavedFilter = (savedFilter) => {
    setFilters(savedFilter.filters);
    fetchBookings();
    setAnchorEl(null);
  };

  const deleteSavedFilter = (index, event) => {
    event.stopPropagation();
    const updated = savedFilters.filter((_, i) => i !== index);
    setSavedFilters(updated);
    localStorage.setItem('savedBookingFilters', JSON.stringify(updated));
    
    setSnackbarMessage('Filter deleted successfully!');
    setSnackbarSeverity('success');
    setSnackbarOpen(true);
  };

  const handleSendEmail = async (booking) => {
    try {
      const response = await fetch(`${baseUrl}api/newdemo/send-email`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          bookingId: booking._id
        }),
      });
  
      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.message || 'Failed to send email');
      }
  
      setSnackbarMessage(data.message || 'Email sent successfully!');
      setSnackbarSeverity('success');
    } catch (error) {
      setSnackbarMessage(error.message);
      setSnackbarSeverity('error');
      console.error('Email error:', error);
    } finally {
      setSnackbarOpen(true);
    }
  };

  const handleSendCustomMessage = async () => {
    if (!customMessage.trim()) {
      setSnackbarMessage('Please enter a message');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    setSendingMessage(true);
    try {
      const response = await fetch(`${baseUrl}api/newdemo/send-custom-message`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          bookingId: selectedBooking._id,
          message: customMessage
        }),
      });

      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.message || 'Failed to send message');
      }

      setSnackbarMessage(data.message || 'Message sent successfully!');
      setSnackbarSeverity('success');
      setMessageDialogOpen(false);
      setCustomMessage('');
    } catch (error) {
      setSnackbarMessage(error.message);
      setSnackbarSeverity('error');
      console.error('Message error:', error);
    } finally {
      setSendingMessage(false);
      setSnackbarOpen(true);
    }
  };

  const openMessageDialog = (booking) => {
    setSelectedBooking(booking);
    setMessageDialogOpen(true);
  };

  const closeMessageDialog = () => {
    setMessageDialogOpen(false);
    setCustomMessage('');
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const openSavedFiltersMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeSavedFiltersMenu = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ mx: "auto", p: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
        <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', color: theme.palette.primary.main }}>
          Your Bookings
        </Typography>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Tooltip title="Refresh">
            <IconButton onClick={fetchBookings} color="primary">
              <Refresh />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      {/* Filter Section */}
      <Card sx={{ mb: 3, p: 2 }}>
        <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <FilterList /> Filter Bookings
        </Typography>
        
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Grid container spacing={2}>
            {/* Status Filter */}
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <InputLabel>Status</InputLabel>
                <Select
                  multiple
                  value={filters.status}
                  onChange={(e) => handleFilterChange('status', e.target.value)}
                  label="Status"
                  renderValue={(selected) => selected.join(', ')}
                >
                  {['Confirmed', 'Pending', 'Cancelled', 'Completed'].map((status) => (
                    <MenuItem key={status} value={status}>
                      <Checkbox checked={filters.status.indexOf(status) > -1} />
                      <ListItemText primary={status} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Date Range Filter */}
            <Grid item xs={12} sm={6} md={3}>
              <DatePicker
                label="From Date"
                value={filters.dateFrom}
                onChange={(newValue) => handleFilterChange('dateFrom', newValue)}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <DatePicker
                label="To Date"
                value={filters.dateTo}
                onChange={(newValue) => handleFilterChange('dateTo', newValue)}
                renderInput={(params) => <TextField {...params} fullWidth />}
                minDate={filters.dateFrom}
              />
            </Grid>

            {/* Region Filter */}
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <InputLabel>Region</InputLabel>
                <Select
                  multiple
                  value={filters.region}
                  onChange={(e) => handleFilterChange('region', e.target.value)}
                  label="Region"
                  renderValue={(selected) => selected.join(', ')}
                >
                  {['North America', 'Europe', 'Asia', 'Middle East', 'Other'].map((region) => (
                    <MenuItem key={region} value={region}>
                      <Checkbox checked={filters.region.indexOf(region) > -1} />
                      <ListItemText primary={region} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Interest Filter */}
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <InputLabel>Interest</InputLabel>
                <Select
                  value={filters.interested === null ? '' : filters.interested.toString()}
                  onChange={(e) => handleFilterChange('interested', e.target.value === '' ? null : e.target.value === 'true')}
                  label="Interest"
                >
                  <MenuItem value="">All</MenuItem>
                  <MenuItem value="true">Interested</MenuItem>
                  <MenuItem value="false">Not Interested</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Search Filter */}
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                fullWidth
                label="Search"
                value={filters.search}
                onChange={(e) => handleFilterChange('search', e.target.value)}
                placeholder="Parent/Child name, email, phone"
              />
            </Grid>

            {/* Action Buttons */}
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
              <Button 
                variant="outlined" 
                onClick={resetFilters}
                startIcon={<Clear />}
              >
                Reset
              </Button>
              
              {savedFilters.length > 0 && (
                <Button 
                  variant="outlined" 
                  onClick={openSavedFiltersMenu}
                  startIcon={<BookmarkBorder />}
                >
                  Saved Filters
                </Button>
              )}
              
              <Button 
                variant="outlined" 
                onClick={() => setSaveDialogOpen(true)}
                startIcon={<Bookmark />}
              >
                Save Filter
              </Button>
              
              <Button 
                variant="contained" 
                onClick={fetchBookings}
                startIcon={<FilterList />}
              >
                Apply Filters
              </Button>
            </Grid>
          </Grid>
        </LocalizationProvider>

        {/* Active Filter Chips */}
        <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mt: 2 }}>
          {filters.status.length > 0 && filters.status.map(status => (
            <Chip
              key={status}
              label={`Status: ${status}`}
              onDelete={() => handleFilterChange('status', filters.status.filter(s => s !== status))}
            />
          ))}
          
          {filters.region.length > 0 && filters.region.map(region => (
            <Chip
              key={region}
              label={`Region: ${region}`}
              onDelete={() => handleFilterChange('region', filters.region.filter(r => r !== region))}
            />
          ))}
          
          {filters.dateFrom && (
            <Chip
              label={`From: ${moment(filters.dateFrom).format('MMM D')}`}
              onDelete={() => handleFilterChange('dateFrom', null)}
            />
          )}
          
          {filters.dateTo && (
            <Chip
              label={`To: ${moment(filters.dateTo).format('MMM D')}`}
              onDelete={() => handleFilterChange('dateTo', null)}
            />
          )}
          
          {filters.interested !== null && (
            <Chip
              label={`Interest: ${filters.interested ? 'Interested' : 'Not Interested'}`}
              onDelete={() => handleFilterChange('interested', null)}
            />
          )}
          
          {filters.search && (
            <Chip
              label={`Search: ${filters.search}`}
              onDelete={() => handleFilterChange('search', '')}
            />
          )}
        </Box>
      </Card>

      {/* Saved Filters Menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeSavedFiltersMenu}
      >
        {savedFilters.map((filter, index) => (
          <MenuItem 
            key={index} 
            onClick={() => applySavedFilter(filter)}
            sx={{ display: 'flex', justifyContent: 'space-between', minWidth: '200px' }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <BookmarkBorder fontSize="small" sx={{ mr: 1 }} />
              {filter.name}
            </Box>
            <IconButton
              size="small"
              onClick={(e) => deleteSavedFilter(index, e)}
              color="error"
            >
              <Clear fontSize="small" />
            </IconButton>
          </MenuItem>
        ))}
      </Menu>

      {/* Bookings Table */}
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
          <CircularProgress />
        </Box>
      ) : bookings.length === 0 ? (
        <Box sx={{ textAlign: 'center', p: 4 }}>
          <ErrorOutline sx={{ fontSize: 60, color: theme.palette.text.secondary, mb: 2 }} />
          <Typography variant="h6" color="textSecondary">
            No bookings found.
          </Typography>
        </Box>
      ) : (
        <TableContainer component={Paper} sx={{ borderRadius: 2, boxShadow: theme.shadows[3] }}>
          <Table>
            <TableHead sx={{ backgroundColor: theme.palette.primary.main }}>
              <TableRow>
                {[
                  'Parent Name',
                  'Child Name',
                  'Grade/Age',
                  'Demo Date',
                  'Status',
                  'Booked by',
                  'Customer Phone',
                  'Customer Email',
                  'Region',
                  'Timezone',
                  'Reschedule Count',
                  'Actions'
                ].map((header) => (
                  <TableCell key={header} sx={{ color: 'white', fontWeight: 'bold' }}>
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {bookings && bookings?.map((booking) => (
                <TableRow key={booking?._id} hover sx={{ '&:nth-of-type(odd)': { backgroundColor: theme.palette.action.hover } }}>
                  <TableCell>{booking?.parentName}</TableCell>
                  <TableCell>{booking?.childName}</TableCell>
                  <TableCell>{booking?.gradeAge}</TableCell>
                  <TableCell>{moment(booking?.demoDateIndia).format('YYYY-MM-DD HH:mm')}</TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        display: 'inline-block',
                        px: 1.5,
                        py: 0.5,
                        borderRadius: 1,
                        backgroundColor:
                          booking.status === 'Confirmed'
                            ? theme.palette.success.light
                            : booking.status === 'Pending'
                            ? theme.palette.warning.light
                            : theme.palette.error.light,
                        color: 'white',
                        fontWeight: 'bold',
                      }}
                    >
                      {booking?.status}
                    </Box>
                  </TableCell>
                  <TableCell>{booking?.salesUser?.name || '-'}</TableCell>
                  <TableCell>{booking?.customerPhone || '-'}</TableCell>
                  <TableCell>{booking?.customerEmail || '-'}</TableCell>
                  <TableCell>{booking?.region}</TableCell>
                  <TableCell>{booking?.timezone}</TableCell>
                  <TableCell>{booking?.rescheduleCount || 0}</TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <Tooltip title="Send Reminder Email">
                        <IconButton
                          color="primary"
                          onClick={() => handleSendEmail(booking)}
                        >
                          <EmailIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Send Custom Message">
                        <IconButton
                          color="secondary"
                          onClick={() => openMessageDialog(booking)}
                        >
                          <MessageIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* Custom Message Dialog */}
      <Dialog 
        open={messageDialogOpen} 
        onClose={closeMessageDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle sx={{ bgcolor: theme.palette.primary.main, color: 'white' }}>
          Send Custom Message
        </DialogTitle>
        <DialogContent sx={{ pt: 3 }}>
          <Typography variant="subtitle1" gutterBottom>
            To: {selectedBooking?.parentName} ({selectedBooking?.customerEmail})
          </Typography>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            Regarding: {selectedBooking?.childName}'s demo class
          </Typography>
          
          <TextField
            label="Your Message"
            multiline
            rows={8}
            fullWidth
            variant="outlined"
            value={customMessage}
            onChange={(e) => setCustomMessage(e.target.value)}
            sx={{ mt: 2 }}
            placeholder={`Dear ${selectedBooking?.parentName},\n\n...\n\nBest regards,\n${selectedBooking?.salesUser?.name || 'Your Education Team'}`}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeMessageDialog} color="primary">
            Cancel
          </Button>
          <Button 
            onClick={handleSendCustomMessage} 
            color="primary"
            variant="contained"
            disabled={sendingMessage || !customMessage.trim()}
            startIcon={sendingMessage ? <CircularProgress size={20} /> : null}
          >
            {sendingMessage ? 'Sending...' : 'Send Message'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Save Filter Dialog */}
      <Dialog open={saveDialogOpen} onClose={() => setSaveDialogOpen(false)}>
        <DialogTitle>Save Current Filter</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Filter Name"
            fullWidth
            value={filterName}
            onChange={(e) => setFilterName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSaveDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleSaveFilter} color="primary">Save</Button>
        </DialogActions>
      </Dialog>

      <Snackbar 
        open={snackbarOpen} 
        autoHideDuration={6000} 
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert 
          onClose={handleSnackbarClose} 
          severity={snackbarSeverity} 
          sx={{ width: '100%' }}
          variant="filled"
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default GetBookings;