import axios from "axios";

const baseUrl =
//   process.env === "PRODUCTION"


    //test server ------------
    // "http://localhost:4000/"
   

    //live server -----------------------------------------------------------------------

"https://backend.oratrics.in/"
     
    //-------------------------------
export default baseUrl;
export const fetchTeams = async () => {
    try {
      const response = await axios.get(baseUrl)+'api/manager';
      return response.data.teams;
    } catch (error) {
      console.error('Error fetching teams:', error);
      throw new Error('Error fetching teams');
    }
  };
  
  // Add member to an existing team
  export const addMemberToTeam = async (teamId, memberId) => {
    try {
      const response = await axios.post(`${baseUrl}api/manager/teams/${teamId}/members`, { memberId });
      return response.data.team;
    } catch (error) {
      console.error('Error adding member to team:', error);
      throw new Error('Error adding member to team');
    }
  };