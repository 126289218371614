import React, { Suspense, lazy, useMemo } from "react";
import { CssBaseline, createTheme, ThemeProvider } from "@mui/material";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const BookDemo = lazy(() => import("./pages/BookDemo"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const Layout = lazy(() => import("./components/Layout"));
const Login = lazy(() => import("./pages/Login"));
const ProtectedRoute = lazy(() => import("./ProtectedRoute"));
const BookingManagement = lazy(() => import("./pages/BookingManagement"));

function App() {
  // Define the theme configuration
  const theme = useMemo(() =>
    createTheme({
      palette: {
        mode: 'light',
        primary: {
          main: '#1976d2',
        },
        secondary: {
          main: '#dc004e',
        },
        background: {
          default: '#f0f2f5',
          paper: '#ffffff',
        },
      },
      typography: {
        fontFamily: 'Roboto, Arial, sans-serif',
      },
    }), []
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route element={<ProtectedRoute />}>
                <Route path="/" element={<Layout />}>
                  <Route index element={<Dashboard />} />
                  <Route path="/book-class" element={<BookDemo />} />
                  <Route path="/view-bookings" element={<BookingManagement />} />
                </Route>
              </Route>
            </Routes>
          </Suspense>
        </LocalizationProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
