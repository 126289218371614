import React, { useState } from 'react';
import { TextField, Button, Typography, Box, InputAdornment, IconButton, Paper } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import baseUrl from '../config';

// Styled components
const GradientContainer = styled(Box)(({ theme }) => ({
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: 'linear-gradient(135deg, #6E7DFF, #1a1d2b)',  // Smooth gradient
}));

const FormContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(5),
  background: 'rgba(255, 255, 255, 0.9)',  // Slightly transparent background
  borderRadius: '12px',  // Rounded corners
  boxShadow: '0px 10px 40px rgba(0, 0, 0, 0.1)',  // Beautiful shadow
  textAlign: 'center',
  width: '100%',
  maxWidth: '400px',  // Centered form
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: '2rem',
  fontWeight: '600',
  color: '#333',
  marginBottom: theme.spacing(3),
  textTransform: 'uppercase',
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    borderRadius: '20px',  // Rounded inputs
    background: '#f5f5f5',  // Light background for inputs
    paddingLeft: theme.spacing(2),
  },
  marginBottom: theme.spacing(3),
  '& .MuiInputLabel-root': {
    fontWeight: '500',
  },
  '& .MuiInputBase-input': {
    padding: theme.spacing(1.5),
  },
}));

const LoginButton = styled(Button)(({ theme }) => ({
  borderRadius: '30px',
  padding: theme.spacing(1.5),
  width: '100%',
  background: '#1a1d2b',  // Button color
  color: '#fff',
  fontWeight: '600',
  textTransform: 'none',
  transition: 'all 0.3s ease',
  '&:hover': {
    background: '#1a1d2b',  // Darker blue on hover
    transform: 'translateY(-3px)',
  },
}));

const EyeIconButton = styled(IconButton)(({ theme }) => ({
  background: 'transparent',
  border: 'none',
  color: '#1a1d2b',
  fontSize: '1.5rem',
}));

const LoginScreen = ({ onLoginSuccess }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false); // Loading state
  const [error, setError] = useState(""); // Error state

  const navigate = useNavigate();

  // Dummy user credentials and roles
  // const users = [
  //   { email: 'admin@admin.com', password: 'password', role: 'manager' },
  //   { email: 'sales@company.com', password: 'password', role: 'sales' },
  //   { email: 'operations@company.com', password: 'password', role: 'operations' },
  // ];

 
console.log(error);

const loginUser = async (event) => {
  event.preventDefault();
  setLoading(true); // Start loading
  setError(""); // Reset any previous errors

  try {
    const response = await fetch(baseUrl + 'api/newdemo/logincrm', { // Use environment variable for URL
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password }),
    });

    const data = await response.json();
    console.log("Login Response: ", data);  // Log the response

    if (response.ok) {
      localStorage.setItem('token', data.token);
      localStorage.setItem('user', JSON.stringify({
        id: data._id,
        name: data.name,
        email: data.email,
        role: data.role,
        uniqueId: data.uniqueId,
      }));
      localStorage.setItem('role', data.role);

      onLoginSuccess(data?.role);
      navigate('/');
    } else {
      setError(data.message || "Unknown error");
    }
  } catch (error) {
    console.error("Error during login:", error);
    setError("An error occurred. Please try again.");
  } finally {
    setLoading(false);
  }
};


  return (
    <GradientContainer>
      <FormContainer>
        <Title variant="h6">Oratrics CRM</Title>
        <StyledTextField
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
          type="email"
        />
        <StyledTextField
          label="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          fullWidth
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <EyeIconButton onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </EyeIconButton>
              </InputAdornment>
            ),
          }}
        />
        <LoginButton onClick={loginUser}>Login</LoginButton>
      </FormContainer>
    </GradientContainer>
  );
};

export default LoginScreen;
