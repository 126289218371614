import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  CssBaseline,
  Box,
  Container,
  Divider,
  Avatar,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { Link, useNavigate } from 'react-router-dom';
import { styled } from '@mui/system';
import useAuth from '../useAuth';

const drawerWidth = 240;


const DrawerContent = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  paddingTop: theme.spacing(2),
}));

const SidebarItem = styled(ListItem)(({ theme }) => ({
  padding: theme.spacing(2, 3),
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
    cursor: 'pointer',
  },
  '&.active': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

const SidebarIcon = styled(ListItemIcon)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  fontSize: '1.5rem',
}));

const SidebarText = styled(ListItemText)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  fontSize: '1rem',
}));

const LogoWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  backgroundColor: theme.palette.primary.main,
  borderRadius: '50%',
  width: '70px',
  height: '70px',
  margin: '0 auto',
}));


const DashboardLayout = ({ children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [mobileOpen, setMobileOpen] = useState(false);
  const [activeItem, setActiveItem] = useState('/dashboard');
  const navigate = useNavigate();
  const userRole = localStorage.getItem('role') || '';

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleMenuItemClick = (path) => {
    setActiveItem(path);
    if (isMobile) setMobileOpen(false);
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate('/');
    window.location.reload();
  };

  useAuth();

  const drawer = (
    <DrawerContent>
      <LogoWrapper>
        <Avatar
          alt="Logo"
          src="https://www.kasandbox.org/programming-images/avatars/leaf-blue.png"
          sx={{ width: 50, height: 50 }}
        />
      </LogoWrapper>

      <Divider sx={{ margin: 2 }} />

      <List>
        <SidebarItem
          button
          component={Link}
          to="/"
          className={activeItem === '/' ? 'active' : ''}
          onClick={() => handleMenuItemClick('/')}
        >
          <SidebarIcon>
            <DashboardIcon />
          </SidebarIcon>
          <SidebarText primary="Dashboard" />
        </SidebarItem>

        <SidebarItem
          button
          component={Link}
          to="/profile"
          className={activeItem === '/profile' ? 'active' : ''}
          onClick={() => handleMenuItemClick('/profile')}
        >
          <SidebarIcon>
            <AccountCircleIcon />
          </SidebarIcon>
          <SidebarText primary="Profile" />
        </SidebarItem>

        <SidebarItem
          button
          component={Link}
          to="/login"
          className={activeItem === '/logout' ? 'active' : ''}
          onClick={handleLogout}
        >
          <SidebarIcon>
            <ExitToAppIcon />
          </SidebarIcon>
          <SidebarText primary="Logout" />
        </SidebarItem>

        <Divider sx={{ margin: 2 }} />

        {userRole === 'sales' && (
          <>
            <SidebarItem
              button
              component={Link}
              to="/book-demo"
              className={activeItem === '/book-demo' ? 'active' : ''}
              onClick={() => handleMenuItemClick('/book-demo')}
            >
              <SidebarText primary="book demo" />
            </SidebarItem>

            <SidebarItem
              button
              component={Link}
              to="/getBookings"
              className={activeItem === '/getBookings' ? 'active' : ''}
              onClick={() => handleMenuItemClick('/getBookings')}
            >
              <SidebarText primary="All Bookings" />
            </SidebarItem>
            <SidebarItem
              button
              component={Link}
              to="/getdemowwebsite"
              className={activeItem === '/getdemowwebsite' ? 'active' : ''}
              onClick={() => handleMenuItemClick('/getdemowwebsite')}
            >
              <SidebarText primary="Booking From Website" />
            </SidebarItem>
            <SidebarItem
              button
              component={Link}
              to="/ApplyCoupon"
              className={activeItem === '/ApplyCoupon' ? 'active' : ''}
              onClick={() => handleMenuItemClick('/ApplyCoupon')}
            >
              <SidebarText primary="ApplyCoupon" />
            </SidebarItem>



            <SidebarItem
              button
              component={Link}
              to="/lead-list"
              className={activeItem === '/lead-list' ? 'active' : ''}
              onClick={() => handleMenuItemClick('/lead-list')}
            >
              <SidebarText primary="Lead List" />
            </SidebarItem>

            <SidebarItem
              button
              component={Link}
              to="/lead-pipeline"
              className={activeItem === '/lead-pipeline' ? 'active' : ''}
              onClick={() => handleMenuItemClick('/lead-pipeline')}
            >
              <SidebarText primary="Lead pipeline" />
            </SidebarItem>
            <SidebarItem
              button
              component={Link}
              to="/lead-team"
              className={activeItem === '/lead-team' ? 'active' : ''}
              onClick={() => handleMenuItemClick('/lead-team')}
            >
              <SidebarText primary="Lead team" />
            </SidebarItem>
          </>
        )}

        {userRole === 'manager' && (
          <>
            <SidebarItem
              button
              component={Link}
              to="/sales-team"
              className={activeItem === '/sales-team' ? 'active' : ''}
              onClick={() => handleMenuItemClick('/sales-team')}
            >
              
              <SidebarText primary="Sales Team" />
            </SidebarItem>
            <SidebarItem
              button
              component={Link}
              to="/sales-team-list"
              className={activeItem === '/sales-team-list' ? 'active' : ''}
              onClick={() => handleMenuItemClick('/sales-team-list')}
            >
                  <SidebarText primary="Sales Team List" />
                  </SidebarItem>
            <SidebarItem
              button
              component={Link}
              to="/upload-lead"
              className={activeItem === '/upload-lead' ? 'active' : ''}
              onClick={() => handleMenuItemClick('/upload-lead')}
            >
              <SidebarText primary="Upload Lead" />
            </SidebarItem>



            <SidebarItem
              button
              component={Link}
              to="/assign-lead"
              className={activeItem === '/assign-lead' ? 'active' : ''}
              onClick={() => handleMenuItemClick('/assign-lead')}
            >
              <SidebarText primary="assign Lead" />
            </SidebarItem>

            <SidebarItem
              button
              component={Link}
              to="/leadlist"
              className={activeItem === '/leadlist' ? 'active' : ''}
              onClick={() => handleMenuItemClick('/leadlist')}
            >
              <SidebarText primary="Lead List" />
            </SidebarItem>


            <SidebarItem
              button
              component={Link}
              to="/operations-team"
              className={activeItem === '/operations-team' ? 'active' : ''}
              onClick={() => handleMenuItemClick('/operations-team')}
            >
              <SidebarText primary="Operations Team" />
            </SidebarItem>

            <SidebarItem
              button
              component={Link}
              to="/manager-dashboard"
              className={activeItem === '/manager-dashboard' ? 'active' : ''}
              onClick={() => handleMenuItemClick('/manager-dashboard')}
            >
              <SidebarText primary="Manager Dashboard" />
            </SidebarItem>

            <SidebarItem
              button
              component={Link}
              to="/team-performance"
              className={activeItem === '/team-performance' ? 'active' : ''}
              onClick={() => handleMenuItemClick('/team-performance')}
            >
              <SidebarText primary="Team Performance Management" />
            </SidebarItem>
          </>
        )}

        {userRole === 'operation' && (
          <>
            <SidebarItem
              button
              component={Link}
              to="/getBookingss"
              className={activeItem === '/getBookingss' ? 'active' : ''}
              onClick={() => handleMenuItemClick('/getBookingss')}
            >
              <SidebarText primary="Bookings" />
            </SidebarItem>
            <SidebarItem
              button
              component={Link}
              to="/getdemoope"
              className={activeItem === '/getdemoope' ? 'active' : ''}
              onClick={() => handleMenuItemClick('/getdemoope')}
            >
              <SidebarText primary="Booking From Website" />
            </SidebarItem>
            {/* <SidebarItem
              button
              component={Link}
              to="/lead-opretion"
              className={activeItem === '/lead-opretion' ? 'active' : ''}
              onClick={() => handleMenuItemClick('/lead-opretion')}
            >
              <SidebarText primary="Lead Pipeline" />
            </SidebarItem> */}
            {/* <SidebarItem
              button
              component={Link}
              to="/lead-perform"
              className={activeItem === '/lead-perform' ? 'active' : ''}
              onClick={() => handleMenuItemClick('/lead-perform')}
            >
              <SidebarText primary="Lead Perform" />
            </SidebarItem>
            <SidebarItem
              button
              component={Link}
              to="/lead-activity"
              className={activeItem === '/lead-activity' ? 'active' : ''}
              onClick={() => handleMenuItemClick('/lead-activity')}
            >
              <SidebarText primary="Lead Activity" />
            </SidebarItem> */}
          </>
        )}
      </List>
    </DrawerContent>
  );

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          zIndex: theme.zIndex.drawer + 1,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            {activeItem.replace('/', '') || 'Dashboard'}
          </Typography>
          <Avatar 
            alt="User" 
            src="https://www.kasandbox.org/programming-images/avatars/leaf-blue.png" 
          />
        </Toolbar>
      </AppBar>

      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      >
        <Drawer
          variant={isMobile ? "temporary" : "permanent"}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        <Container maxWidth={false} sx={{ mt: 2 }}>
          {children}
        </Container>
      </Box>
    </Box>
  );
};

export default DashboardLayout;