    import React, { useState, useEffect } from 'react';
    import {
    Box, Container, CircularProgress, Typography,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Paper, IconButton, Tooltip, Button, Dialog, DialogTitle,
    DialogContent, DialogActions, TextField, Select, MenuItem,
    InputLabel, FormControl, Snackbar, Alert, Chip, Grid,
    Checkbox, Menu, Card,ListItemText
    } from '@mui/material';
    import {
    Refresh as RefreshIcon,
    School as SchoolIcon,
    EventAvailable as RescheduleIcon,
    ThumbUp as InterestIcon,
    Email as EmailIcon,
    Bookmark as BookmarkIcon,
    BookmarkBorder as BookmarkBorderIcon,
    FilterAlt as FilterIcon,
    CheckCircle as CheckCircleIcon,
    ThumbDown as NotInterestIcon,

    } from '@mui/icons-material';

  
    import moment from 'moment-timezone';
    import axios from 'axios';
    import { DatePicker } from '@mui/x-date-pickers/DatePicker';
    import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
    import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

    const GetBookingsPage = ({ api, showSnackbar, handleApiError }) => {
    const [bookings, setBookings] = useState([]);
    const [teachers, setTeachers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedBooking, setSelectedBooking] = useState(null);
    const [openAssignDialog, setOpenAssignDialog] = useState(false);
    const [openRescheduleDialog, setOpenRescheduleDialog] = useState(false);
    const [openInterestDialog, setOpenInterestDialog] = useState(false);
    const [openMessageDialog, setOpenMessageDialog] = useState(false);
    const [assignData, setAssignData] = useState({ teacherId: '', joinUrl: '', startUrl: '' });
    const [rescheduleData, setRescheduleData] = useState({ newDate: '', newTime: '', joinUrl: '', startUrl: '' });
    const [interestData, setInterestData] = useState({ isInterested: null });
    const [messageData, setMessageData] = useState({ subject: '', message: '' });
    const [filters, setFilters] = useState({
        status: [],
        dateFrom: null,
        dateTo: null,
        teacherId: '',
        region: [],
        interested: null,
        search: ''
    });
    const [savedFilters, setSavedFilters] = useState([]);
    const [saveDialogOpen, setSaveDialogOpen] = useState(false);
    const [filterName, setFilterName] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);

    const fetchBookings = async () => {
        setLoading(true);
        try {
        const params = new URLSearchParams();
        
        if (filters.status.length > 0) {
            params.append('status', filters.status.join(','));
        }
        
        if (filters.dateFrom) {
            params.append('dateFrom', filters.dateFrom.format('YYYY-MM-DD'));
        }
        
        if (filters.dateTo) {
            params.append('dateTo', filters.dateTo.format('YYYY-MM-DD'));
        }
        
        if (filters.teacherId) {
            params.append('teacherId', filters.teacherId);
        }
        
        if (filters.region.length > 0) {
            params.append('region', filters.region.join(','));
        }
        
        if (filters.interested !== null) {
            params.append('interested', filters.interested.toString());
        }
        
        if (filters.search) {
            params.append('search', filters.search);
        }

        const response = await api.get(`api/newdemo/operationboooking?${params.toString()}`);
        setBookings(response.data);
        setError(null);
        } catch (err) {
        setError(handleApiError(err));
        } finally {
        setLoading(false);
        }
    };

    const fetchTeachers = async () => {
        try {
        const response = await api.get('api/newdemo/teachers');
        setTeachers(response.data);
        } catch (err) {
        handleApiError(err);
        }
    };

    const handleSaveFilter = () => {
        if (!filterName.trim()) return;
        
        const newSavedFilters = [
        ...savedFilters,
        {
            name: filterName,
            filters: filters,
            createdAt: new Date()
        }
        ];
        
        localStorage.setItem('savedFilters', JSON.stringify(newSavedFilters));
        setSavedFilters(newSavedFilters);
        setFilterName('');
        setSaveDialogOpen(false);
        showSnackbar('Filter saved successfully!', 'success');
    };

    const handleLoadFilter = (savedFilter) => {
        setFilters(savedFilter.filters);
        fetchBookings();
        setAnchorEl(null);
        showSnackbar(`Filter "${savedFilter.name}" loaded`, 'info');
    };

    const resetFilters = () => {
        setFilters({
        status: [],
        dateFrom: null,
        dateTo: null,
        teacherId: '',
        region: [],
        interested: null,
        search: ''
        });
        fetchBookings();
    };

    useEffect(() => {
        fetchBookings();
        fetchTeachers();
        
        const saved = localStorage.getItem('savedFilters');
        if (saved) {
        setSavedFilters(JSON.parse(saved));
        }
    }, []);

    if (loading && bookings.length === 0) {
        return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
            <CircularProgress size={60} />
        </Box>
        );
    }

    if (error) {
        return (
        <Box sx={{ textAlign: 'center', p: 4 }}>
            <Typography variant="h6" color="error" sx={{ mb: 2 }}>
            Error Loading Data
            </Typography>
            <Typography sx={{ mb: 3 }}>{error}</Typography>
            <Button 
            variant="contained" 
            onClick={() => window.location.reload()}
            startIcon={<RefreshIcon />}
            >
            Retry
            </Button>
        </Box>
        );
    }

    const FilterComponent = () => (
        <Card sx={{ mb: 3, p: 2 }}>
        <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <FilterIcon /> Filter Bookings
        </Typography>
        <Grid container spacing={2}>
            {/* Status Filter */}
            <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth>
                <InputLabel>Status</InputLabel>
                <Select
                multiple
                value={filters.status}
                onChange={(e) => setFilters({...filters, status: e.target.value})}
                label="Status"
                renderValue={(selected) => selected.join(', ')}
                >
                {['pending', 'assigned', 'scheduled', 'completed', 'notConducted', 'hold'].map((status) => (
                    <MenuItem key={status} value={status}>
                    <Checkbox checked={filters.status.indexOf(status) > -1} />
                    <ListItemText primary={status} />
                    </MenuItem>
                ))}
                </Select>
            </FormControl>
            </Grid>

            {/* Date Range Filter */}
            <Grid item xs={12} sm={6} md={3}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                label="From Date"
                value={filters.dateFrom}
                onChange={(newValue) => setFilters({...filters, dateFrom: newValue})}
                renderInput={(params) => <TextField {...params} fullWidth />}
                />
            </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                label="To Date"
                value={filters.dateTo}
                onChange={(newValue) => setFilters({...filters, dateTo: newValue})}
                renderInput={(params) => <TextField {...params} fullWidth />}
                minDate={filters.dateFrom}
                />
            </LocalizationProvider>
            </Grid>

            {/* Teacher Filter */}
            <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth>
                <InputLabel>Teacher</InputLabel>
                <Select
                value={filters.teacherId}
                onChange={(e) => setFilters({...filters, teacherId: e.target.value})}
                label="Teacher"
                >
                <MenuItem value="">All Teachers</MenuItem>
                {teachers.map((teacher) => (
                    <MenuItem key={teacher._id} value={teacher._id}>
                    {teacher.name}
                    </MenuItem>
                ))}
                </Select>
            </FormControl>
            </Grid>

            {/* Region Filter */}
            <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth>
                <InputLabel>Region</InputLabel>
                <Select
                multiple
                value={filters.region}
                onChange={(e) => setFilters({...filters, region: e.target.value})}
                label="Region"
                renderValue={(selected) => selected.join(', ')}
                >
                {['North America', 'RoW', 'India', 'Other'].map((region) => (
                    <MenuItem key={region} value={region}>
                    <Checkbox checked={filters.region.indexOf(region) > -1} />
                    <ListItemText primary={region} />
                    </MenuItem>
                ))}
                </Select>
            </FormControl>
            </Grid>

            {/* Interest Filter */}
            <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth>
                <InputLabel>Interest</InputLabel>
                <Select
                value={filters.interested === null ? '' : filters.interested.toString()}
                onChange={(e) => setFilters({...filters, interested: e.target.value === '' ? null : e.target.value === 'true'})}
                label="Interest"
                >
                <MenuItem value="">All</MenuItem>
                <MenuItem value="true">Interested</MenuItem>
                <MenuItem value="false">Not Interested</MenuItem>
                </Select>
            </FormControl>
            </Grid>

            {/* Search Filter */}
            <Grid item xs={12} sm={6} md={3}>
            <TextField
                fullWidth
                label="Search"
                value={filters.search}
                onChange={(e) => setFilters({...filters, search: e.target.value})}
                placeholder="Parent/Child name, email, phone"
            />
            </Grid>

            {/* Action Buttons */}
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
            <Button 
                variant="outlined" 
                onClick={resetFilters}
            >
                Reset Filters
            </Button>
            
            {savedFilters.length > 0 && (
                <Button 
                variant="outlined" 
                startIcon={<BookmarkBorderIcon />}
                onClick={(e) => setAnchorEl(e.currentTarget)}
                >
                Load Saved
                </Button>
            )}
            
            <Button 
                variant="outlined" 
                onClick={() => setSaveDialogOpen(true)}
                startIcon={<BookmarkIcon />}
            >
                Save Filter
            </Button>
            
            <Button 
                variant="contained" 
                onClick={fetchBookings}
                startIcon={<RefreshIcon />}
            >
                Apply Filters
            </Button>
            </Grid>
        </Grid>

        {/* Active Filter Chips */}
        <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mt: 2 }}>
            {filters.status.length > 0 && filters.status.map(status => (
            <Chip
                key={status}
                label={`Status: ${status}`}
                onDelete={() => setFilters({
                ...filters,
                status: filters.status.filter(s => s !== status)
                })}
            />
            ))}
            
            {filters.region.length > 0 && filters.region.map(region => (
            <Chip
                key={region}
                label={`Region: ${region}`}
                onDelete={() => setFilters({
                ...filters,
                region: filters.region.filter(r => r !== region)
                })}
            />
            ))}
            
            {filters.dateFrom && (
            <Chip
                label={`From: ${filters.dateFrom.format('MMM D')}`}
                onDelete={() => setFilters({...filters, dateFrom: null})}
            />
            )}
            
            {filters.dateTo && (
            <Chip
                label={`To: ${filters.dateTo.format('MMM D')}`}
                onDelete={() => setFilters({...filters, dateTo: null})}
            />
            )}
            
            {filters.teacherId && (
            <Chip
                label={`Teacher: ${teachers.find(t => t._id === filters.teacherId)?.name || ''}`}
                onDelete={() => setFilters({...filters, teacherId: ''})}
            />
            )}
            
            {filters.interested !== null && (
            <Chip
                label={`Interest: ${filters.interested ? 'Interested' : 'Not Interested'}`}
                onDelete={() => setFilters({...filters, interested: null})}
            />
            )}
            
            {filters.search && (
            <Chip
                label={`Search: ${filters.search}`}
                onDelete={() => setFilters({...filters, search: ''})}
            />
            )}
        </Box>
        </Card>
    );

    return (
        <Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography variant="h6">Demo Bookings</Typography>
            <Tooltip title="Refresh">
            <IconButton onClick={fetchBookings}>
                <RefreshIcon />
            </IconButton>
            </Tooltip>
        </Box>

        <FilterComponent />

        <TableContainer component={Paper} sx={{ borderRadius: 2, boxShadow: 3 }}>
  <Table sx={{ minWidth: 1200 }} size="small">
    <TableHead sx={{ backgroundColor: 'primary.main' }}>
      <TableRow>
        {[
          { label: 'Parent', key: 'parentName', width: '10%' },
          { label: 'Child', key: 'childName', width: '8%' },
          { label: 'Grade/Age', key: 'gradeAge', width: '8%' },
          { label: 'Demo Date', key: 'demoDate', width: '12%' },
          { label: 'Booked By', key: 'bookedBy', width: '10%' },
          { label: 'Contact', key: 'contact', width: '12%' },
          { label: 'Region', key: 'region', width: '8%' },
          { label: 'Status', key: 'status', width: '12%' },
          { label: 'Actions', key: 'actions', width: '20%', align: 'center' }
        ].map((column) => (
          <TableCell 
            key={column.key}
            sx={{ 
              color: 'white', 
              fontWeight: 'bold',
              width: column.width,
              textAlign: column.align || 'left'
            }}
          >
            {column.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
    <TableBody>
      {bookings.map((booking) => (
        <TableRow 
          key={booking._id} 
          hover
          sx={{ '&:last-child td': { borderBottom: 0 } }}
        >
          {/* Parent Column */}
          <TableCell>
            <Box>
              <Typography fontWeight="medium">{booking.parentName}</Typography>
              <Typography variant="body2" color="text.secondary">
                {booking.customerEmail}
              </Typography>
            </Box>
          </TableCell>

          {/* Child Column */}
          <TableCell>{booking.childName}</TableCell>

          {/* Grade/Age Column */}
          <TableCell>{booking.gradeAge}</TableCell>

          {/* Demo Date Column */}
          <TableCell>
            <Box>
              <Typography>
                {moment(booking.demoDateIndia).format('MMM D, YYYY')}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {moment(booking.demoDateIndia).format('h:mm A')}
                {booking.timezone && ` (${booking.timezone})`}
              </Typography>
            </Box>
          </TableCell>

          {/* Booked By Column */}
          <TableCell>
            {booking.salesUser?.name || '-'}
          </TableCell>

          {/* Contact Column */}
          <TableCell>
            <Box>
              <Typography>{booking.customerPhone || '-'}</Typography>
              {booking.additionalPhone && (
                <Typography variant="body2" color="text.secondary">
                  {booking.additionalPhone}
                </Typography>
              )}
            </Box>
          </TableCell>

          {/* Region Column */}
          <TableCell>
            <Chip 
              label={booking.region} 
              size="small"
              color="secondary"
            />
          </TableCell>

          {/* Status Column */}
          <TableCell>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Chip 
                label={booking.status} 
                size="small"
                color={
                  booking.status === 'completed' ? 'success' :
                  booking.status === 'pending' ? 'warning' : 'info'
                }
              />
              {booking.interested !== null && (
                <Chip
                  label={booking.interested ? '✓' : '✗'}
                  color={booking.interested ? 'success' : 'error'}
                  size="small"
                  sx={{ minWidth: 24, height: 24 }}
                />
              )}
            </Box>
          </TableCell>

          {/* Actions Column */}
          <TableCell>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                        <Tooltip title="Assign Teacher">
                        <IconButton
                            size="small"
                            onClick={() => {
                            setSelectedBooking(booking);
                            setAssignData({ 
                                teacherId: booking.teacher?._id || '', 
                                joinUrl: booking.demoLink?.joinUrl || '',
                                startUrl: booking.demoLink?.startUrl || '' 
                            });
                            setOpenAssignDialog(true);
                            }}
                        >
                            <SchoolIcon fontSize="small" />
                        </IconButton>
                        </Tooltip>
                        <Tooltip title="Reschedule">
                        <IconButton
                            size="small"
                            onClick={() => {
                            setSelectedBooking(booking);
                            setRescheduleData({
                                newDate: moment(booking.demoDateIndia).format('YYYY-MM-DD'),
                                newTime: booking.demoSlotIST || '',
                                joinUrl: booking.demoLink?.joinUrl || '',
                                startUrl: booking.demoLink?.startUrl || ''
                            });
                            setOpenRescheduleDialog(true);
                            }}
                        >
                            <RescheduleIcon fontSize="small" />
                        </IconButton>
                        </Tooltip>
                        <Tooltip title="Update Interest">
                        <IconButton
                            size="small"
                            onClick={() => {
                            setSelectedBooking(booking);
                            setInterestData({
                                isInterested: booking.interested || null
                            });
                            setOpenInterestDialog(true);
                            }}
                        >
                            <InterestIcon fontSize="small" />
                        </IconButton>
                        </Tooltip>
                        <Tooltip title="Send Message">
                        <IconButton
                            size="small"
                            onClick={() => {
                            setSelectedBooking(booking);
                            setMessageData({
                                subject: `Update: ${booking.childName}'s Demo Class`,
                                message: `Dear ${booking.parentName},\n\n`
                            });
                            setOpenMessageDialog(true);
                            }}
                        >
                            <EmailIcon fontSize="small" />
                        </IconButton>
                        </Tooltip>
                    </Box>
                    </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
</TableContainer>
        {/* Assign Teacher Dialog */}
        <Dialog open={openAssignDialog} onClose={() => setOpenAssignDialog(false)} maxWidth="sm" fullWidth>
            <DialogTitle sx={{ bgcolor: 'primary.main', color: 'white' }}>
            Assign Teacher
            </DialogTitle>
            <DialogContent sx={{ pt: 3 }}>
            <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Teacher</InputLabel>
                <Select
                value={assignData.teacherId}
                onChange={(e) => setAssignData({...assignData, teacherId: e.target.value})}
                label="Teacher"
                >
                {teachers.map((teacher) => (
                    <MenuItem key={teacher._id} value={teacher._id}>
                    {teacher.name} ({teacher.email})
                    </MenuItem>
                ))}
                </Select>
            </FormControl>
            <TextField
                fullWidth
                label="Join URL"
                value={assignData.joinUrl}
                onChange={(e) => setAssignData({...assignData, joinUrl: e.target.value})}
                sx={{ mb: 2 }}
            />
            <TextField
                fullWidth
                label="Start URL"
                value={assignData.startUrl}
                onChange={(e) => setAssignData({...assignData, startUrl: e.target.value})}
            />
            </DialogContent>
            <DialogActions>
            <Button onClick={() => setOpenAssignDialog(false)}>Cancel</Button>
            <Button 
                onClick={async () => {
                try {
                    const response = await api.put(`api/newdemo/assign/${selectedBooking._id}`, assignData);
                    showSnackbar('Teacher assigned successfully!', 'success');
                    setOpenAssignDialog(false);
                    fetchBookings();
                } catch (err) {
                    handleApiError(err);
                }
                }}
                variant="contained" 
                color="primary"
            >
                Assign
            </Button>
            </DialogActions>
        </Dialog>

        {/* Reschedule Dialog */}
        <Dialog open={openRescheduleDialog} onClose={() => setOpenRescheduleDialog(false)} maxWidth="sm" fullWidth>
            <DialogTitle sx={{ bgcolor: 'primary.main', color: 'white' }}>
            Reschedule Demo
            </DialogTitle>
            <DialogContent sx={{ pt: 3 }}>
            <TextField
                fullWidth
                label="New Date"
                type="date"
                value={rescheduleData.newDate}
                onChange={(e) => setRescheduleData({...rescheduleData, newDate: e.target.value})}
                InputLabelProps={{ shrink: true }}
                sx={{ mb: 2 }}
            />
            <TextField
                fullWidth
                label="New Time (HH:mm)"
                value={rescheduleData.newTime}
                onChange={(e) => setRescheduleData({...rescheduleData, newTime: e.target.value})}
                placeholder="14:30"
                sx={{ mb: 2 }}
            />
            <TextField
                fullWidth
                label="Join URL"
                value={rescheduleData.joinUrl}
                onChange={(e) => setRescheduleData({...rescheduleData, joinUrl: e.target.value})}
                sx={{ mb: 2 }}
            />
            <TextField
                fullWidth
                label="Start URL"
                value={rescheduleData.startUrl}
                onChange={(e) => setRescheduleData({...rescheduleData, startUrl: e.target.value})}
            />
            </DialogContent>
            <DialogActions>
            <Button onClick={() => setOpenRescheduleDialog(false)}>Cancel</Button>
            <Button 
                onClick={async () => {
                try {
                    const response = await api.put(`api/newdemo/reschedule/${selectedBooking._id}`, rescheduleData);
                    showSnackbar('Demo rescheduled successfully!', 'success');
                    setOpenRescheduleDialog(false);
                    fetchBookings();
                } catch (err) {
                    handleApiError(err);
                }
                }}
                variant="contained" 
                color="primary"
            >
                Reschedule
            </Button>
            </DialogActions>
        </Dialog>

        {/* Update Interest Dialog */}
        <Dialog open={openInterestDialog} onClose={() => setOpenInterestDialog(false)} maxWidth="sm" fullWidth>
            <DialogTitle sx={{ bgcolor: 'primary.main', color: 'white' }}>
            Update Interest Status
            </DialogTitle>
            <DialogContent sx={{ pt: 3 }}>
            <FormControl fullWidth>
                <InputLabel>Interest Status</InputLabel>
                <Select
                value={interestData.isInterested}
                onChange={(e) => setInterestData({...interestData, isInterested: e.target.value})}
                label="Interest Status"
                >
                <MenuItem value={true}>Interested</MenuItem>
                <MenuItem value={false}>Not Interested</MenuItem>
                </Select>
            </FormControl>
            </DialogContent>
            <DialogActions>
            <Button onClick={() => setOpenInterestDialog(false)}>Cancel</Button>
            <Button 
                onClick={async () => {
                try {
                    const response = await api.put(`api/newdemo/interest/${selectedBooking._id}`, interestData);
                    showSnackbar('Interest status updated successfully!', 'success');
                    setOpenInterestDialog(false);
                    fetchBookings();
                } catch (err) {
                    handleApiError(err);
                }
                }}
                variant="contained" 
                color="primary"
            >
                Update
            </Button>
            </DialogActions>
        </Dialog>

        {/* Send Message Dialog */}
        <Dialog open={openMessageDialog} onClose={() => setOpenMessageDialog(false)} maxWidth="md" fullWidth>
            <DialogTitle sx={{ bgcolor: 'primary.main', color: 'white' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <EmailIcon />
                <span>Send Custom Message</span>
            </Box>
            </DialogTitle>
            <DialogContent sx={{ pt: 3 }}>
            <Typography variant="subtitle1" gutterBottom>
                To: {selectedBooking?.parentName} ({selectedBooking?.customerEmail})
            </Typography>
            <Typography variant="body2" color="text.secondary" gutterBottom>
                Regarding: {selectedBooking?.childName}'s demo class
            </Typography>
            
            <TextField
                fullWidth
                label="Subject"
                value={messageData.subject}
                onChange={(e) => setMessageData({...messageData, subject: e.target.value})}
                sx={{ mb: 2 }}
            />
            
            <TextField
                fullWidth
                multiline
                rows={8}
                label="Your Message"
                value={messageData.message}
                onChange={(e) => setMessageData({...messageData, message: e.target.value})}
            />

            <Box sx={{ mt: 2, p: 2, bgcolor: 'grey.100', borderRadius: 1 }}>
                <Typography variant="subtitle2" gutterBottom>
                Quick Templates:
                </Typography>
                <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                <Chip 
                    label="Reminder" 
                    onClick={() => {
                    setMessageData({
                        subject: `Reminder: ${selectedBooking?.childName}'s Demo Class`,
                        message: `Dear ${selectedBooking?.parentName},\n\nThis is a friendly reminder about your child's upcoming demo class on ${moment(selectedBooking?.demoDateIndia).format('MMMM Do [at] h:mm A')}.\n\nPlease ensure you have the meeting link ready:\n${selectedBooking?.demoLink?.joinUrl || 'Link will be provided soon'}\n\nBest regards,\nOperations Team`
                    });
                    }}
                    sx={{ cursor: 'pointer' }}
                />
                <Chip 
                    label="Follow-up" 
                    onClick={() => {
                    setMessageData({
                        subject: `Follow-up: ${selectedBooking?.childName}'s Demo Class`,
                        message: `Dear ${selectedBooking?.parentName},\n\nWe hope you enjoyed the demo class. We'd love to hear your feedback and discuss next steps.\n\nPlease let us know if you have any questions or if you'd like to schedule another session.\n\nBest regards,\nOperations Team`
                    });
                    }}
                    sx={{ cursor: 'pointer' }}
                />
                </Box>
            </Box>
            </DialogContent>
            <DialogActions>
            <Button onClick={() => setOpenMessageDialog(false)}>Cancel</Button>
            <Button 
                onClick={async () => {
                try {
                    const payload = {
                    bookingId: selectedBooking._id,
                    message: messageData.message
                    };
                    const response = await api.post('api/newdemo/send-custom-ope', payload);
                    showSnackbar('Message sent successfully!', 'success');
                    setOpenMessageDialog(false);
                } catch (err) {
                    handleApiError(err);
                }
                }}
                variant="contained" 
                color="primary"
            >
                Send Message
            </Button>
            </DialogActions>
        </Dialog>

        {/* Save Filter Dialog */}
        <Dialog open={saveDialogOpen} onClose={() => setSaveDialogOpen(false)}>
            <DialogTitle>Save Current Filter</DialogTitle>
            <DialogContent>
            <TextField
                autoFocus
                margin="dense"
                label="Filter Name"
                fullWidth
                value={filterName}
                onChange={(e) => setFilterName(e.target.value)}
            />
            </DialogContent>
            <DialogActions>
            <Button onClick={() => setSaveDialogOpen(false)}>Cancel</Button>
            <Button onClick={handleSaveFilter} color="primary">Save</Button>
            </DialogActions>
        </Dialog>

        {/* Saved Filters Menu */}
        <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
        >
            {savedFilters.map((filter, index) => (
            <MenuItem key={index} onClick={() => handleLoadFilter(filter)}>
                {filter.name}
                <Typography variant="caption" color="text.secondary" sx={{ ml: 2 }}>
                {moment(filter.createdAt).format('MMM D')}
                </Typography>
            </MenuItem>
            ))}
        </Menu>
        </Box>
    );
    };

    export default GetBookingsPage;